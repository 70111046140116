import React, { useContext, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Input } from 'reactstrap';
import { WrapperField } from './WrapperField';
import { jsonFields } from './jsonFields';
import { DialogContext } from '../../store/context/DialogContext';
import { shouldShowField } from './helper';

export const TestForm = () => {
  const [data, setData] = useState({});
  const [showErr, setShowErr] = useState(false);
  const [errors, setErrors] = useState({});
  const { showError } = useContext(DialogContext);
  const [jsonFieldsMap] = useState(
    jsonFields.reduce((acc, field) => {
      acc[field.field] = field;
      return acc;
    }, {})
  );

  useEffect(() => {
    const initialErrors = {};
    jsonFields.map((field) => {
      if ( field.required && (!data[field.field] || data[field.field] === "") && shouldShowField(data, field.showIf)
      ) {
        if (!errors?.[field.field]  ) {
          initialErrors[field.field] = field.errorTypes?.required || field.error || "This field is required.";
        } 
      } 
    });
    const shownErrors = {};
    jsonFields.map((field) => {
      if (shouldShowField(data, field.showIf)
      ) {
        if (errors?.[field.field]) {
          shownErrors[field.field] = errors?.[field.field];
        }
      }
    });
    setErrors({ ...shownErrors, ...initialErrors});
  }, [data]);

  const handleNext = () => {
    setShowErr(true);

    for (const field of jsonFields) {
      const fieldError = errors[field.field];
      if (fieldError) {
        showError(fieldError);
        break;
      }
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <div className="mt-3">
        {Object.keys(errors).map((key) => (
          <div key={key} style={{ color: 'red' }}>
            {errors[key]}
          </div>
        ))}
      </div>

      <h3>Dynamic Form Example</h3>
      <div>Form Data: {JSON.stringify(data)}</div>
      <Input
        type="checkbox"
        checked={data?.check || false}
        onChange={(e) => setData({ ...data, check: e.target.checked })}
      />
      <label> Check to show conditional fields</label>

      <WrapperField
        jsonField={jsonFieldsMap["firstName"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, firstName: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["country"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, country: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["detailedNotes"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, detailedNotes: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["employmentStatus"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, employmentStatus: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["custodyType"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, custodyType: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["skills"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, skills: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["age"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, age: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["password"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, password: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["phone"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, phone: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["email"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, email: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["appointmentDate"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, appointmentDate: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["dob"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, dob: errorMsg }))}
      />

      <WrapperField
        jsonField={jsonFieldsMap["operateEstDate"]}
        dataObj={data}
        onChange={(updatedData) => setData(updatedData)}
        showError={showErr}
        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, operateEstDate: errorMsg }))}
      />

      {/* {jsonFields.map((field, idx) => (
        <WrapperField
          key={idx}
          jsonField={field}
          dataObj={data}
          onChange={(updatedData) => setData(updatedData)}
          showError={showErr}
          onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, [field.field]: errorMsg }))}
          className=""
        />
      ))} */}

      <Button onClick={handleNext} className="mt-4">Next</Button>
    </div>
  );
};

export default TestForm;
