export const calculateNextStep = (data, wizard, editMode) => {
    const selectedBusinesses = data?.businesses || [];
    const msbCheck = selectedBusinesses?.includes("MSB_No_Crypto");
    const cryptoExchangeCheck = selectedBusinesses?.includes("Crypto_Exchange_OTC_Liquidity_Remittance_FX_MSB");
    const fundAndFamilyCheck = selectedBusinesses?.includes("Fund_Family_Office");
    const accountUpgradeCheck = selectedBusinesses?.includes("Corporate_Higher_Limits_No_Client_Funds");
    if (wizard == "BusinessType") {
      return { wizard: "AboutBusiness" };
    } else if (wizard == "AboutBusiness") {
      return { wizard: "AccountActivity"};
    } else if (wizard == "AccountActivity") {
      if (msbCheck || cryptoExchangeCheck) {
        return { wizard: "MoneyServicesBusiness" };
      } else if (fundAndFamilyCheck) {
        return { wizard: "FundFamilyOffices" };
      } else if (accountUpgradeCheck) {
        return { wizard: "CorporateAccountUpgrade" };
      } else if (editMode)  {
        return { wizard: "StartAgain" };
      } else {
        return { wizard: "Documents" };
      }
    } else if (wizard == "MoneyServicesBusiness") {
      if (cryptoExchangeCheck) {
        return { wizard: "AssetsInvolvement" };
      } else if (fundAndFamilyCheck) {
        return { wizard: "FundFamilyOffices" };
      } else if (accountUpgradeCheck) {
        return { wizard: "CorporateAccountUpgrade" };
      } else if (msbCheck) {
        return { wizard: "ComplianceDetails" };
      } else if (editMode) {
        return { wizard: "StartAgain" };
      } else {
        return { wizard: "Documents" };
      }
    } else if (wizard === "AssetsInvolvement") {
      if (fundAndFamilyCheck) {
        return { wizard: "FundFamilyOffices" };
      } else if (accountUpgradeCheck) {
        return { wizard: "CorporateAccountUpgrade" };
      } else if (cryptoExchangeCheck || fundAndFamilyCheck || msbCheck  ) {
        return { wizard: "ComplianceDetails" };
      } else if (editMode) {
        return { wizard: "StartAgain" };
      } else {
        return { wizard: "Documents" };
      }
    } else if (wizard == "FundFamilyOffices") {
      if (accountUpgradeCheck) {
        return { wizard: "CorporateAccountUpgrade" };
      } else if (cryptoExchangeCheck || fundAndFamilyCheck  || msbCheck) {
        return { wizard: "ComplianceDetails" };
      } else if (editMode) {
        return { wizard: "StartAgain" };
      } else {
        return { wizard: "Documents" };
      }
    } else if (wizard === "CorporateAccountUpgrade") {
      if (cryptoExchangeCheck || fundAndFamilyCheck || msbCheck) {
        return { wizard: "ComplianceDetails" };
      } else if (editMode) {
        return { wizard: "StartAgain" };
      } else {
        return { wizard: "Documents" };
      }
    } else if (wizard === "ComplianceDetails") {
      if (editMode) {
        return { wizard: "StartAgain" };
      } else {
        return { wizard: "Documents" };
      }
    } else if (wizard === "Documents") {
      return { wizard: "ThankYou" }; 
    } else if (wizard === "ThankYou") {
      return { wizard: "ThankYou" };
    }

    return { wizard: "ThankYou" };
  };


  export const calculatePrevStep = (data, wizard) => {
    const selectedBusinesses = data?.businesses || [];
    const msbCheck = selectedBusinesses?.includes("MSB_No_Crypto");
    const cryptoExchangeCheck = selectedBusinesses?.includes("Crypto_Exchange_OTC_Liquidity_Remittance_FX_MSB");
    const fundAndFamilyCheck = selectedBusinesses?.includes("Fund_Family_Office");
    const accountUpgradeCheck = selectedBusinesses?.includes("Corporate_Higher_Limits_No_Client_Funds");

    if (wizard === "ThankYou") {
      return { wizard: "Documents" };
    } else if (wizard === "Documents") {
      if (cryptoExchangeCheck || fundAndFamilyCheck || msbCheck) {
        return { wizard: "ComplianceDetails" };
      } else if (accountUpgradeCheck) {
        return { wizard: "CorporateAccountUpgrade" };
      } else if (fundAndFamilyCheck) {
        return { wizard: "FundFamilyOffices" };
      } else if (cryptoExchangeCheck) {
        return { wizard: "AssetsInvolvement" };
      } else {
        return { wizard: "AccountActivity" };
      }
    } else if (wizard === "ComplianceDetails") {
      if (accountUpgradeCheck) {
        return { wizard: "CorporateAccountUpgrade" };
      } else if (fundAndFamilyCheck) {
        return { wizard: "FundFamilyOffices" };
      } else if (cryptoExchangeCheck) {
        return { wizard: "AssetsInvolvement" };
      } else if (msbCheck) {
        return { wizard: "MoneyServicesBusiness" };
      }
    } else if (wizard === "CorporateAccountUpgrade") {
      if (fundAndFamilyCheck) {
        return { wizard: "FundFamilyOffices" };
      } else if (cryptoExchangeCheck) {
        return { wizard: "AssetsInvolvement" };
      } else if (msbCheck) {
        return { wizard: "MoneyServicesBusiness" };
      } else {
        return { wizard: "AccountActivity" };
      }
    } else if (wizard === "FundFamilyOffices") {
      if (cryptoExchangeCheck) {
        return { wizard: "AssetsInvolvement" };
      } else if (msbCheck) {
        return { wizard: "MoneyServicesBusiness" };
      } else {
        return { wizard: "AccountActivity" };
      }
    } else if (wizard === "AssetsInvolvement") {
      if (msbCheck || cryptoExchangeCheck) {
        return { wizard: "MoneyServicesBusiness" };
      } else {
        return { wizard: "AccountActivity" };
      }
    } else if (wizard === "MoneyServicesBusiness") {
      return { wizard: "AccountActivity" };
    } else if (wizard === "AccountActivity") {
      return { wizard: "AboutBusiness" };
    } else if (wizard === "AboutBusiness") {
      return { wizard: "BusinessType" };
    } else if (wizard === "BusinessType") {
      return { wizard: "BusinessType" }; 
    }
  
    return { wizard: "BusinessType" }; 
  };
  

export const convertArrayToDict = (array) => {
  return array.reduce((acc, obj) => {
    if (obj.label && !acc[obj.label]) {
      acc[obj.label] = obj;
    }
    return acc;
  }, {});
}