import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
// import businessLiteConfig from '../../../../BusinessLite.json';
import Logo from "../../../../assets/images/Logo.svg";
import CountrySelectService from '../../../../components/CSC/CountrySelect/CountrySelectService';
import Stepper from '../../../../components/Common/Tab/Stepper';
import { DialogContext } from '../../../../store/context/DialogContext';
import { doGET, doPUT } from '../../../../util/HttpUtil';
import { getOrdinalSuffix, handleShowIfCondition } from '../../../../util/Util';
import ExtraQuestions from '../ExtraQuestions';
import { AskMoreMessage, ThankYou } from '../components';
import ApplicationDocRedo from '../components/IndividualLite/ApplicationDocRedo';
import { GroupedFields, addFieldsMapping, cleanupMappings } from '../constants';
import ButtonGroup from './components/ButtonGroup';
import Page1 from './components/Page1';
import Page2 from './components/Page2';
import Page3 from './components/Page3';
import Page4 from './components/Page4';
import Page5 from './components/Page5';
import Page6 from './components/Page6';

const checkIsAskForInfo = (steps) => {
    return (steps ?? [])?.find((v) => v?.status == 2)
}

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling effect
    });
};


function convertArrayToDict(array) {
    return array.reduce((acc, obj) => {
        // Assuming 'label' is the property you want to use as the key
        if (obj.label && !acc[obj.label]) {
            acc[obj.label] = obj;
        }
        return acc;
    }, {});
}

const getStepNumber = (status) => {
    switch (status) {
        case "Application Form":
            return 7;
        case "KYC Verification":
            return Number.MAX_SAFE_INTEGER;
        case "Sanctions Screening":
            return Number.MAX_SAFE_INTEGER;
        case "Document Verification":
            return Number.MAX_SAFE_INTEGER;
        case "Risk Assessment":
            return Number.MAX_SAFE_INTEGER;
        default:
            return 0
    }
}
const ApplyBusinessLite = ({ asLabel, editMode = false, formId, refreshId, onChange = () => { } }) => {
    const { edit_id } = useParams();
    let { showError } = useContext(DialogContext);
    const [data, setData] = useState({
        entities: [{}],
        individuals: [{ isPrimaryUser: true, }],
    });
    const [applicationForm, setApplicationForm] = useState({});
    const [isAskForInfo, setIsAskForInfo] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [editId, setEditID] = useState(edit_id ?? formId);
    const [applicationSteps, setApplicationSteps] = useState({});

    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [step, setStep] = useState(1);

    const [businessValidationFields, setbusinessValidationFields] = useState([])
    const [validationkeyValue, setvalidationkeyValue] = useState({});

    const [centralErrors, setCentralErrors] = useState({});
    const decrementStep = () => {
        setStep(prev => prev - 1);
    }

    useEffect(() => {
        onChange(removeUnnecessaryThingsBeforeUpdate(data))
    }, [data]);

    function handleChange(path, value, isCheckbox = false) {
        setData(prevData => {
            let updatedData = _.cloneDeep(prevData);

            // Update data based on checkbox or regular value
            if (isCheckbox) {
                if (_.get(updatedData, path)) {
                    _.set(updatedData, path, _.without(_.get(updatedData, path), value));
                } else {
                    let updatedArray = _.get(updatedData, path) || [];
                    updatedArray.push(value);
                    _.set(updatedData, path, updatedArray);
                }
            } else {
                _.set(updatedData, path, value);
            }

            // Cleanup logic integrated based on certain conditions

            cleanupMappings.forEach(({ conditionPath, conditionValue, keys, conditionOperator = '===' }) => {
                const dataValue = _.get(updatedData, conditionPath);
                let conditionMet = false;

                // Evaluate the condition based on the operator
                switch (conditionOperator) {
                    case '===':
                        conditionMet = dataValue === conditionValue;
                        break;
                    case '!==':
                        conditionMet = dataValue !== conditionValue;
                        break;
                    default:
                        conditionMet = dataValue === conditionValue;
                }

                // Cleanup keys if condition is met
                if (conditionMet) {
                    keys.forEach(key => {
                        const keyParts = key.split('.');
                        let current = updatedData;

                        // Traverse the object to find the key path
                        for (let i = 0; i < keyParts.length - 1; i++) {
                            if (current && current[keyParts[i]] !== undefined) {
                                current = current[keyParts[i]];
                            } else {
                                current = undefined;
                                break;
                            }
                        }

                        // Delete the key if it exists
                        if (current && current[keyParts[keyParts.length - 1]] !== undefined) {
                            delete current[keyParts[keyParts.length - 1]];
                        }
                    });
                }
            });

            addFieldsMapping.forEach(({ conditionPath, conditionValue, addValues, conditionOperator = '===' }) => {
                const dataValue = _.get(updatedData, conditionPath);
                let conditionMet = false;

                switch (conditionOperator) {
                    case '===':
                        conditionMet = dataValue === conditionValue;
                        break;
                    case '!==':
                        conditionMet = dataValue !== conditionValue;
                        break;
                    case '==':
                        conditionMet = dataValue == conditionValue; // Loose comparison
                        break;
                    case '!=':
                        conditionMet = dataValue != conditionValue; // Loose comparison
                        break;
                    case '>':
                        conditionMet = dataValue > conditionValue;
                        break;
                    case '<':
                        conditionMet = dataValue < conditionValue;
                        break;
                    default:
                        conditionMet = dataValue === conditionValue;
                }

                if (conditionMet) {
                    addValues.forEach(({ key, value }) => {
                        const keyParts = key.split('.');
                        let current = updatedData;

                        for (let i = 0; i < keyParts.length - 1; i++) {
                            if (!current[keyParts[i]]) {
                                current[keyParts[i]] = {}; // Create the path if it doesn't exist
                            }
                            current = current[keyParts[i]];
                        }
                        if (current[keyParts[keyParts.length - 1]] == undefined || current[keyParts[keyParts.length - 1]] == null || !current[keyParts[keyParts.length - 1]]?.length) {
                            current[keyParts[keyParts.length - 1]] = value;
                        }
                    });
                }
            });


            return updatedData;
        });
    }


    function handleChangeViaObject(updateObject) {
        setData(prevData => {
            let updatedData = _.cloneDeep(prevData);

            Object.keys(updateObject).forEach(key => {
                _.set(updatedData, key, updateObject[key]);
            });

            return updatedData;
        });
    }

    useEffect(() => {
        if (formId) {
            setEditID(formId)
        }
    }, [formId])

    const fetchCountryCodes = async () => {
        try {
            await CountrySelectService.fetch();
        } catch (error) {
            showError(error);
        }
    };


    const getValidationJSON = async () => {
        try {
            await fetchCountryCodes();
            const response = await doGET("/api/o/cyclos/fields-json?group=Businesses_Lite");
            if (response.status === 200) {
                // const bJSON = businessLiteConfig;
                const bJSON = response?.data ?? {};
                setbusinessValidationFields(bJSON?.fields)
                const fieldsMap = bJSON?.fields.reduce((acc, field) => {
                    if (field?.field) {
                        acc[field.field] = field;
                    }
                    return acc;
                }, {});

                setvalidationkeyValue(fieldsMap)
            }
        } catch (error) {
            showError(error);
        }
    };


    useEffect(() => {
        getValidationJSON()
    }, []);


    const fetchDetail = async (stepProp) => {
        let businessLiteResponse = {}
        let applicationFormResponse = {}
        let askForInfoResponse = {};

        setDataLoading(true)
        try {
            if (editId) {
                localStorage.setItem("formId", editId);
                const response = await doGET(`/api/business-lite/detail?id=${editId}`)
                businessLiteResponse = response.data ?? {}
                const applicationResponse = await doGET(`/api/application-form/detail?id=${editId}`)
                if (applicationResponse?.status == 200) {
                    applicationFormResponse = applicationResponse?.data
                    if (applicationFormResponse?.steps?.length) {
                        const askInfoStep = checkIsAskForInfo(applicationFormResponse?.steps)
                        const stepsObj = convertArrayToDict(applicationFormResponse?.steps)
                        setApplicationSteps(stepsObj);

                        if (stepsObj["Document Verification"]?.status > 0 && [1, 3, 4]?.includes(stepsObj["Document Verification"]?.status)
                            && stepsObj["Application Form"]?.status > 0 && [1, 3, 4]?.includes(stepsObj["Application Form"]?.status)
                        ) {
                            setStep(Number.MAX_SAFE_INTEGER);
                        }

                        if (Object.keys(askInfoStep ?? {}).length) {
                            const askInfoStepData = { ...(askInfoStep ?? {}), isAskForInfo: true, step: getStepNumber(askInfoStep?.label) }
                            setIsAskForInfo(askInfoStepData)
                            askForInfoResponse = askInfoStepData
                            setStep(Number.MAX_SAFE_INTEGER)
                        }
                        else {
                            setIsAskForInfo(null)
                        }
                    }

                    setApplicationForm(applicationResponse?.data)

                    if (businessLiteResponse?.individuals?.length) {
                        let individual = businessLiteResponse.individuals[0];

                        const missingEmail = !individual.email;
                        const missingAccessType = !individual.accessType;

                        if (missingEmail && missingAccessType) {
                            individual.email = businessLiteResponse.email;
                            individual.accessType = "NoGroup";
                            individual.isPrimaryUser = true;
                        } else if (missingEmail) {
                            individual.email = businessLiteResponse.email;
                            individual.isPrimaryUser = true;
                        } else if (missingAccessType) {
                            individual.accessType = "NoGroup";
                            individual.isPrimaryUser = true;
                        }
                    } else {
                        // individuals array is empty or doesn't exist
                        businessLiteResponse.individuals = [{
                            email: businessLiteResponse.email,
                            accessType: "NoGroup",
                            isPrimaryUser: true
                        }];
                    }


                    setData({
                        ...businessLiteResponse,
                    })


                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setDataLoading(false)
            if (stepProp) setStep(stepProp)
            scrollToTop()
        }
    }

    useEffect(() => {
        fetchDetail();
    }, [editId, refreshId]);

    const removeUnnecessaryThingsBeforeUpdate = (payload) => {
        let payloadToPass = payload ?? {};

        if (payloadToPass?.individuals?.length) {
            payloadToPass.individuals[0].isPrimaryUser = true;
        }

        if (!payloadToPass?.hasEntity) {
            payloadToPass.entities = [];
        }

        // Initialize an array to hold keys that need to be omitted from the returned payload
        const keysToOmit = [];

        // Conditionally add keys to omit based on the presence of landlinePhone and mobilePhone
        if (!payloadToPass?.landlinePhone) {
            keysToOmit.push('landlineCountryCode');
        }

        if (!payloadToPass?.mobilePhone) {
            keysToOmit.push('mobileCountryCode');
        }

        // Use lodash to omit empty strings and the specified keys
        const cleanedPayload = _.omit(
            _.omitBy(payloadToPass, value => value === ""),
            keysToOmit
        );

        return cleanedPayload;
    };


    const handleUpdate = async ({ nextStep, payload, isfetchDetail, isLastStep = false }) => {
        if ((payload ?? data)?._id) {
            setLoading(true)
            setHasError(false);
            try {
                if (!asLabel) {
                    const response = await doPUT(`/api/business-lite/update`, removeUnnecessaryThingsBeforeUpdate(payload ?? data))
                    if (response?.status == 200) {

                        if (nextStep === 3) {
                            const applicationFormData = await doGET(`/api/application-form/detail?id=${editId}`)
                            if (applicationFormData?.status == 200) {
                                const updatedpplicationFormData = await doPUT(`/api/application-form/update`, {
                                    ...applicationFormData?.data,
                                })
                                if (updatedpplicationFormData?.status == 200) {
                                    setData(prev => ({
                                        ...prev,
                                        ...response?.data,
                                    }))
                                }
                            }
                        } else {
                            setData(prev => ({
                                ...prev,
                                ...response?.data,
                            }))
                        }
                    }
                }


                if (isLastStep && !asLabel) {
                    const submitResponse = await doGET(`/api/business-lite/submit?id=${editId}&markFormSubmit=true&markDocSubmit=true`)
                    if (submitResponse?.status == 200) {
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        if (isfetchDetail && nextStep) {
                            await fetchDetail(nextStep);
                            setLoading(false);
                        }
                    }
                } else {
                    if (isfetchDetail && nextStep) fetchDetail(nextStep)
                    else if (nextStep) setStep(nextStep)
                    scrollToTop()
                    setLoading(false);
                }
                setLoading(false);
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
                setHasError(true);
                showError(error)
            }
            finally {
                setLoading(false)
            }
        } else {
            if (nextStep) fetchDetail(nextStep)
        }
    };

    const handleNext = (wizard) => {
        let errors = [];
        const requiredFields = businessValidationFields
            .filter(({ wizard: fieldWizard, required, showIf }) =>
                fieldWizard === wizard &&
                (required || showIf?.required) &&
                (!showIf || handleShowIfCondition(data, showIf))
            )
            .map(({ field, error }) => ({ field, error }));



        const fieldsToValidate = wizard === 2
            ? requiredFields?.filter(({ field }) =>
                !GroupedFields?.includes(field)
            )
            : requiredFields;

        // Find the index of "mobilePhone" in fieldsToValidate
        const mobilePhoneIndex = fieldsToValidate.findIndex(({ field }) => field === 'mobilePhone');



        Object?.values(centralErrors)?.forEach((v) => {
            if (v?.hasError) {
                errors?.push(v?.errorMsg)
            }
        })

        fieldsToValidate?.forEach(({ field, error }) => {
            const fieldValue = field?.includes('.') ? _.get(data, field) : data[field];
            if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
                errors.push(error || `The field ${field} is required.`);
            }

            const fieldError = handleValidation(field, fieldValue);
            if (fieldError) {
                errors.push(fieldError);
            }
        });

        if (wizard === 2) {


            if (data?.individuals) {

                let isNoneShareHolderOrAuthorizedUser = -1;
                let mobileSet = new Set();
                let emailSet = new Set();

                const maxAuthorizedUsers = 11;
                const authorizedUsers = data?.individuals?.filter(ind => ind?.isAuthorizedUser) || [];

                if (authorizedUsers.length > maxAuthorizedUsers) {
                    errors.push(`Only ${maxAuthorizedUsers} authorized users are allowed. Currently, there are ${authorizedUsers.length}.`);
                }
                const shareholderUsers = data?.individuals?.filter(ind => ind?.isShareHolder) || [];

                const totalOwnershipPercentage = shareholderUsers.reduce((total, shareholder) => {
                    const percentOwner = parseFloat(shareholder?.percentOwner);
                    const validPercentOwner = isNaN(percentOwner) ? 0 : percentOwner;
                    return total + validPercentOwner;
                }, 0);

                if (totalOwnershipPercentage > 100) {
                    errors.push(`The total percentage of ownership cannot exceed 100%. Currently, it is ${totalOwnershipPercentage}%.`);
                }


                data?.individuals?.forEach((user, idx) => {
                    const ordinalIdx = getOrdinalSuffix(idx + 1);

                    let validations = [
                        handleValidation('individuals[].firstName', user?.firstName),
                        handleValidation('individuals[].middleName', user?.middleName),
                        handleValidation('individuals[].lastName', user?.lastName),
                        handleValidation('individuals[].address.buildingNumber', user?.address?.buildingNumber),
                        handleValidation('individuals[].address.street', user?.address?.street),
                        handleValidation('individuals[].address.city', user?.address?.city),
                        handleValidation('individuals[].address.state', user?.address?.state),
                        handleValidation('individuals[].address.pinCode', user?.address?.pinCode),
                        handleValidation('individuals[].address.country', user?.address?.country),
                        handleValidation('individuals[].mobilePhone', user?.mobilePhone),
                        handleValidation('individuals[].dob', user?.dob),
                        handleValidation('individuals[].nationality', user?.nationality),
                        handleValidation('individuals[].email', user?.email),
                        handleValidation('individuals[].taxId', user?.taxId)
                    ];

                    if (user?.isAuthorizedUser) {
                        validations = [
                            ...validations,
                            handleValidation('individuals[].role', user?.role),
                            handleValidation('individuals[].Mother_Maiden_Name', user?.Mother_Maiden_Name),
                            handleValidation('individuals[].accessType', user?.accessType),
                        ]
                    }


                    if (user?.isShareHolder) {
                        validations = [
                            ...validations,
                            handleValidation('individuals[].sourceOfWealthList', user?.sourceOfWealthList),
                            handleValidation('individuals[].percentOwner', user?.percentOwner),
                        ]
                    }

                    if (!user?.isShareHolder && !user?.isAuthorizedUser) {
                        isNoneShareHolderOrAuthorizedUser = idx
                    }

                    validations.forEach(validation => {
                        if (validation) errors.push(validation + " for " + ordinalIdx + " officer");
                    });


                    // Check unique mobileCountryCode + mobilePhone combination
                    if (user?.mobileCountryCode && user?.mobilePhone) {
                        const mobileCombination = `${user.mobileCountryCode}-${user.mobilePhone}`;
                        if (mobileSet.has(mobileCombination)) {
                            errors.push(`Duplicate mobile phone combination: ${user.mobileCountryCode}-${user.mobilePhone} for ${ordinalIdx} user.`);
                        } else {
                            mobileSet.add(mobileCombination);
                        }
                    }

                    if (user?.email) {
                        if (emailSet.has(user.email)) {
                            errors.push(`Duplicate email: ${user.email} for ${ordinalIdx} user.`);
                        } else {
                            emailSet.add(user.email);
                        }
                    }

                    let singleSelectionFields = [
                        { field: 'isShareHolder', validationKey: 'individuals[].isShareHolder' },
                        { field: 'isAuthorizedUser', validationKey: 'individuals[].isAuthorizedUser' },
                    ];


                    if (user?.isAuthorizedUser) {
                        singleSelectionFields = [
                            ...singleSelectionFields,
                            { field: 'haveControlOverEntity', validationKey: 'individuals[].haveControlOverEntity' },
                            { field: 'isDirector', validationKey: 'individuals[].isDirector' },
                            { field: 'isAuthorizedContact', validationKey: 'individuals[].isAuthorizedContact' },
                            { field: 'isAgentWithAttorneyPower', validationKey: 'individuals[].isAgentWithAttorneyPower' }
                        ]
                    }




                    singleSelectionFields.forEach(({ field, validationKey }) => {
                        const validation = handleValidation(validationKey, user?.[field]);
                        if (validation) errors.push(validation + " for " + ordinalIdx + " user");
                    });


                });

                var addressTypeSet = new Set();

                data?.addressList?.forEach((address, idx) => {
                    const ordinalIdx = getOrdinalSuffix(idx + 1);
                    let validations = [
                        handleValidation('addressList[].type', address?.type),
                        handleValidation('addressList[].buildingNumber', address?.buildingNumber),
                        handleValidation('addressList[].street', address?.street),
                        handleValidation('addressList[].city', address?.city),
                        handleValidation('addressList[].state', address?.state),
                        handleValidation('addressList[].pinCode', address?.pinCode),
                        handleValidation('addressList[].country', address?.country),
                    ];

                    if (addressTypeSet.has(address.type)) {
                        errors.push(`Duplicate address type: ${address.type} for ${ordinalIdx} address.`);
                    } else {
                        addressTypeSet.add(address.type);
                    }

                    validations.forEach(validation => {
                        if (validation) errors.push(validation + " for " + ordinalIdx + "additional address");
                    });
                });

                if (data?.hasEntity) {
                    data?.entities?.forEach((entity, idx) => {
                        const ordinalIdx = getOrdinalSuffix(idx + 1);
                        let validations = [
                            handleValidation("entities[].relations", entity?.relations),
                            handleValidation("entities[].name", entity?.name),
                            handleValidation("entities[].incorpCountry", entity?.incorpCountry),
                            handleValidation("entities[].website", entity?.website),
                            handleValidation('entities[].businessNature', entity?.businessNature),
                            handleValidation("entities[].yearOfIncorporation", entity?.yearOfIncorporation),
                        ];
                        if (entity?.relations === "shareholder") {
                            validations = [
                                ...validations,
                                handleValidation("entities[].percentOwner", entity?.percentOwner),
                                handleValidation("entities[].sourceOfWealthList", entity?.sourceOfWealthList),
                            ]
                        }
                        validations.forEach(validation => {
                            if (validation) errors.push(validation + " for " + ordinalIdx + " entity");
                        });
                    });
                }


                if (isNoneShareHolderOrAuthorizedUser >= 0) {
                    const ordinalIdx = getOrdinalSuffix(isNoneShareHolderOrAuthorizedUser + 1);
                    showError(`${ordinalIdx} user should be either shareholder or authorized user.`)
                    return
                }

            }




        }

        if (errors.length > 0) {
            setHasError(true);
            showError(errors[0]);
            return false;
        }

        setHasError(false);
        return true;
    }

    const handleSubmit = async (v, isLastStep, isfetchDetail) => {
        if (handleNext(v - 1)) {
            await handleUpdate({ nextStep: v, isLastStep, isfetchDetail });
        }

    };

    if (!asLabel && !editMode && (
        (isAskForInfo?.status == 2 && [2]?.includes(applicationSteps["Document Verification"]?.status))
        || applicationSteps["Application Form"]?.status > 0 && [0]?.includes(applicationSteps["Document Verification"]?.status)
    )) {
        return <div>
            <ApplicationDocRedo
                recordId={editId}
                asLabel={asLabel}
                formId={editId}
                formType={"BUSINESS_PLUS"}
                type={"Business Plus"} />;
        </div>;
    }

    if (!asLabel && !editMode) {
        if (step >= (8)
            && isAskForInfo?.status == 2
            && ["Application Form"]?.includes(isAskForInfo?.label)
        ) {
            return <div>
                <AskMoreMessage
                    title="Business Plus"
                    data={data}
                    remark={(data?.remark ?? "")}
                    loading={loading}
                    onNext={() => {
                        setStep(isAskForInfo?.step ?? 1)
                    }}
                />
            </div>
        }
    }

    if (!asLabel && !editMode) {
        if (step >= (data?.asks?.length > 0 ? 8 : 7) || applicationForm?.steps?.[0]?.status == 1 || (!["Document Verification", "Application Form"]?.includes(isAskForInfo?.label) && [1, 3, 4]?.includes(applicationForm?.steps?.[0]?.status))) {
            return <ThankYou type={"Business Plus"} />
        }
    }

    const handleValidation = (field, value, index) => {
        const fieldConfig = businessValidationFields.find(f => f.field === field);
        if (!fieldConfig) return null;

        const {
            required,
            regex,
            min,
            max,
            error,
            type,
            options,
            label,
            showIf
        } = fieldConfig;

        if (field === "Illegal_Immoral_Disclosure" && (value ?? [])?.includes("none_of_the_above") && value.length !== 1) {
            return  '"Is your business involved in any of the following" cannot have "none of the above" selected along with other values.';
        }

        switch (type) {
            case "string":
                if ((required || showIf?.required) && (!value || value.toString().trim() === "")) {
                    return error || `${label} is required.`;
                }
                if (min && value?.length < min && value) {
                    return `${label} should be at least ${min} characters long.`;
                }
                if (max && value?.length > max && value) {
                    return `${label} should be less than ${max} characters long.`;
                }
                if (regex && !new RegExp(regex).test(value) && value) {
                    return `Please enter valid ${label}.`;
                }
                break;

            case "number":
                if ((required || showIf?.required) && (!value || value.toString().trim() === "")) {
                    return error || `${label} is required.`;
                }
                if (min && value?.length < min) {
                    return `${label} should be at least ${min} characters long.`;
                }
                if (max && value?.length > max) {
                    return `${label} should be less than ${max} characters long.`;
                }
                if (regex && !new RegExp(regex).test(value)) {
                    return error || `Please enter valid ${label}.`;
                }
                break;

            case "singleSelection":
                if ((required || showIf?.required) && value == null) {
                    return error || `Please select an option for ${label}.`;
                }
                if (options && (required || showIf?.required)) {
                    const validOptions = options.map(opt => opt.value);
                    if (!validOptions.includes(value)) {
                        return `Please select an option for ${label}.`;
                    }
                }
                break;

            case "multipleSelection":
                if ((required || showIf?.required) && (!value || value.toString().trim() === "")) {
                    return error || `Please select at least one option for ${label}.`;
                }
                const selectedOptions = Array.isArray(value) ? value : [value];
                if ((required || showIf?.required) && selectedOptions?.length === 0) {
                    return error || `Please select at least one option for ${label}.`;
                }
                break;
            case "date":
                if ((required || showIf?.required) && (!value || value.toString().trim() === "")) {
                    return error || `${label} is required.`;
                }
                break;
        }

        return null;
    };

    return (
        <div style={{ margin: "0px" }} className={!asLabel && !editMode ? 'd-flex justify-content-center align-items-center' : ""}>
            <Form className={asLabel || editMode ? "" : 'form-content '} noValidate>
                {(dataLoading || !Object.keys(validationkeyValue)?.length) ? <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-1 fv_ondato_rule">
                    <FaSpinner size={14} className="spinner" />
                </div>
                    :
                    <div className='d-flex flex-column justify-content-center align-items-center  '>
                        {(!asLabel && !editMode) && <img
                            src={Logo}
                            alt="fv-bank"
                            className="logo logo-dark header-logo"
                        />}
                        <div className="heading">Business Plus Account Application</div>

                    </div>
                }

                {(dataLoading || !Object.keys(validationkeyValue)?.length) ? null :
                    <Stepper
                        activeTab={step}
                        asLabel={asLabel || editMode}
                        onChange={(s) => {
                            setStep(s);
                        }}

                    >
                        <div num={1} label={("Introduction and Account Features")}>
                            <Page1
                                asLabel={(asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)) && !editMode}
                                hideButtons={asLabel || editMode}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError || editMode}
                                loading={loading}
                                onNextClick={() => setStep(2)}
                                step={step}
                                onBackClick={decrementStep}
                                handleValidation={handleValidation}
                            />
                        </div>

                        <div num={2} label={"Business Information"}>
                            <Page2
                                asLabel={(asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)) && !editMode}
                                data={data}
                                handleChange={handleChange}
                                setCentralErrors={setCentralErrors}
                                centralErrors={centralErrors}
                                handleChangeViaObject={handleChangeViaObject}
                                hasError={hasError || editMode}
                                setData={setData}
                                onNextClick={() => handleSubmit(3)}
                                step={step}
                                onBackClick={decrementStep}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                                editMode={editMode}
                            />
                            <ButtonGroup loading={loading} asLabel={asLabel || editMode} onNextClick={() => {
                                handleSubmit(3)
                            }} step={step} onBackClick={decrementStep} />
                        </div>

                        <div num={3} label={"Business Activities and Account Usage"}>
                            <Page3
                                asLabel={(asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)) && !editMode}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError || editMode}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                            />
                            <ButtonGroup loading={loading} asLabel={asLabel || editMode} onNextClick={() => handleSubmit(4)} step={step} onBackClick={decrementStep} />
                        </div>

                        <div num={4} label={"Business Compliance and Licensing"}>
                            <Page4
                                asLabel={(asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)) && !editMode}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError || editMode}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                            />
                            <ButtonGroup loading={loading} asLabel={asLabel || editMode} onNextClick={() => handleSubmit(5)} step={step} onBackClick={decrementStep} />
                        </div>
                        {(!asLabel && !editMode) &&
                            <div num={5} label={"Document Upload Center"}>
                                <Page5
                                    isAskForInfo={false}
                                    remark={"Remark Here"}
                                    recordId={edit_id}
                                    asLabel={asLabel}
                                    handleUpdate={handleUpdate}
                                    setHasError={setHasError}
                                    data={data}
                                    handleValidation={handleValidation}
                                    validationkeyValue={validationkeyValue}
                                    loading={loading}
                                    onNextClick={() => handleSubmit(6)}
                                    step={step}
                                    onBackClick={decrementStep}
                                />
                            </div>
                        }


                        <div num={6} label={"Referral and Source Information"}>
                            <Page6
                                asLabel={(asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)) && !editMode}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError || editMode}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                            />
                            <ButtonGroup limit={6} loading={loading} asLabel={asLabel || editMode} onNextClick={() => handleSubmit(7, true, true)} step={step} onBackClick={decrementStep} />
                        </div>

                        {
                            (((data?.asks?.length && isAskForInfo?.status == 2 && [2]?.includes(applicationSteps["Application Form"]?.status)))
                                || (asLabel && data?.asks?.length)
                            )
                            && <div num={7} label={"Extra Information"}>
                                <ExtraQuestions
                                    questionIds={data?.asks?.map((v) => v?.question_id).filter(Boolean) ?? []}
                                    data={data}
                                    formType={"BUSINESS_PLUS"}
                                    formId={editId}
                                    asLabel={asLabel}
                                    dataLoading={loading || dataLoading}
                                    handleValidation={handleValidation}
                                    onBack={() => {
                                        // setStep(5)
                                    }}
                                    onSubmit={() => {
                                        fetchDetail(Number.MAX_SAFE_INTEGER)
                                    }}
                                />
                            </div>
                        }
                    </Stepper>}
            </Form>


        </div>
    );
}

export default ApplyBusinessLite;