import React from 'react';
import { InputLabel } from '../../components/IndividualLite/UserDetailsForm';
import { Row } from 'reactstrap';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import _ from 'lodash';
import { WrapperField } from '../../../../../components/InputField/WrapperField';


const BusinessType = ({ setErrors, asLabel, editMode, data, handleChange, pageNum, loading, step, bPrimeJSON, showErr, handleSubmit }) => {
    return (
        <>
            <div className='mb-3'>
                <p className='pt-2'>
                    This form is for Business Plus Customers to upgrade their account from Business Plus to Business Prime. Business Prime is for accountholders with a need for higher limits or for accounts involving third party funds such as an MSB, Non Bank Financial Services, VASPS, Exchanges and OTC's.
                </p>

                <InputLabel label="Authorized person completing this application" labelStyle={{ fontSize: 15 }} />
                <Row>
                    <WrapperField
                        disabled={asLabel}
                        className="col-md-6"
                        jsonField={bPrimeJSON["firstName"]}
                        dataObj={data}
                        onChange={(updatedValue) => handleChange(updatedValue)}
                        showError={showErr}
                        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, firstName: errorMsg }))}

                    />
                    <WrapperField
                        disabled={asLabel}
                        className="col-md-6"
                        jsonField={bPrimeJSON["lastName"]}
                        dataObj={data}
                        onChange={(updatedValue) => handleChange(updatedValue)}
                        showError={showErr}
                        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, lastName: errorMsg }))}

                    />
                    <WrapperField
                        disabled={asLabel}
                        jsonField={bPrimeJSON["applicantType"]}
                        dataObj={data}
                        onChange={(updatedValue) => handleChange(updatedValue)}
                        showError={showErr}
                        onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, applicantType: errorMsg }))}
                    />
                </Row>
                <p className='pt-3'>
                    For the purposes of this application, FV Bank considers all of the following to be Money Services Businesses, whether a license is required or not in your jurisdictions. (Exchanges, OTC Providers, Liquidity Providers, Wallet Providers, Non Bank Financial Institutions, VASPS, DLT's EMI's, PSD license holders, Brokers, Remittance Provides and FX Providers.)
                </p>
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["businesses"]}
                    dataObj={data}
                    className="my-1 col-sm-12 d-flex"
                    onChange={(updatedValue) => handleChange(updatedValue)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, businesses: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    className=""
                    jsonField={bPrimeJSON["businessOther"]}
                    dataObj={data}
                    onChange={(updatedValue) => handleChange(updatedValue)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, businessOther: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["sourceOfFunds"]}
                    dataObj={data}
                    onChange={(updatedValue) => handleChange(updatedValue)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, sourceOfFunds: errorMsg }))}
                    className="my-1 col-sm-12 d-flex"
                />

            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                hideBackButton
                asLabel={asLabel || editMode}
                limit={10}
                onNextClick={() => handleSubmit({ isLastStep: false })}
                step={step}
                onBackClick={() => { }}
            />
        </>
    )
}

export default BusinessType;
