import React, { useContext, useState, useEffect } from 'react';
import { ThreeBounce } from 'better-react-spinkit';
import GoBackButton from '../../../../../components/Buttons/GoBackButton';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { DialogContext } from '../../../../../store/context/DialogContext';
import { doGET } from '../../../../../util/HttpUtil';
import ProofOfDocuments from '../../BusinessLiteForms/components/ProofOfDocument';
import { FaSpinner } from 'react-icons/fa';
import { useTranslation } from '../../../../../store/context/TranslationContext';

const ProofOfAddressDocument = ({
    hideButtons,
    isAskForInfo,
    remark,
    recordId,
    asLabel,
    setStep,
    handleUpdate,
    setHasError,
    setErrorMessage,
    data
}) => {

    const [loading, setLoading] = useState(false);
    const [iframeLoading, setIframeLoading] = useState(false);
    const [iframeVisible, setIframeVisible] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');
    const { showMessage, showError } = useContext(DialogContext);
    const { t } = useTranslation();

    const fetchIframeUrl = async () => {
        setLoading(true);
        try {
            const response = await doGET(`/api/yug-capture/widget?type=ProofOfAddress&ubo_id=${data?.ubo_id}&id=${recordId}`);
            if (response?.status === 200 && response?.data?.data?.url) {
                setIframeUrl(response?.data?.data?.url);
            } else {
                showError("Failed to load the document capture URL.");
            }
        } catch (error) {
            showError(error.message || "Failed to load the document capture URL.");
        } finally {
            setLoading(false);
        }
    };

    const checkIsDocumentUploaded = async ({ source }) => {
        setLoading(true);
        try {
            const response = await doGET(`/api/application-doc/grid?rows=-1&form_id=${recordId}${data?.ubo_id ? `&ubo_id=${data?.ubo_id}` : ""}`);
            if (response?.status === 200 && response?.data?.rows[0]?.attachment?.urls?.length) {
                setLoading(false);
                return true;
            }
        } catch (error) {
            showError(error);
        }
        setLoading(false);
        return false;
    };


    const handleSubmit = async ({ source }) => {
        const errors = [];
        const isDocumentUploaded = await checkIsDocumentUploaded({ source });

        if (!isDocumentUploaded) {
            errors.push("Please upload proof of address.");
            showError("Please upload proof of address.");
        }
        if (errors.length > 0) {
            setHasError(true);
            setErrorMessage(errors.join(' '));
            return;
        }

        setHasError(false);
        setLoading(true);
        await handleUpdate({ nextStep: 4, isfetchDetail: true, isLastStep: true });
        setLoading(false);
    };

    const handleIframeMessage = (event) => {
        if (event.data === '{"event":"DOCUMENT-CAPTURED"}') {
            handleSubmit({ source: "YUG" });
        }
    };

    const handleUploadOfflineClick = () => {
        setIframeVisible(false);
    };

    const handleDownloadOnlineClick = () => {
        setIframeVisible(true);
    };

    // useEffect(() => {
    //     fetchIframeUrl();
    //     window.addEventListener('message', handleIframeMessage);
    //     return () => {
    //         window.removeEventListener('message', handleIframeMessage);
    //     };
    // }, []);

    const handleIframeLoadStart = () => {
        setIframeLoading(true);
    };

    const handleIframeLoadEnd = () => {
        setIframeLoading(false);
    };

    return (
        <div className='row'>
            {iframeVisible ? (
                <>
                    {(loading || !iframeUrl) ? (
                        <div style={{ width: '100%', height: 'calc(100vh - 340px)' }} className="d-flex justify-content-center align-items-center w-100 flex-1 fv_ondato_rule">
                            <FaSpinner size={14} className="spinner" />
                        </div>
                    ) : (null)}
                    <iframe
                        src={iframeUrl}
                        style={{ width: '100%', height: iframeLoading ? 0 : 'calc(100vh - 320px)' }}
                        onLoadStart={handleIframeLoadStart}
                        onLoad={handleIframeLoadEnd}
                    />
                </>
            ) : (
                <ProofOfDocuments
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={t("Address Proof")}
                    field={"addressProof"}
                    showCategory={"ProofOfAddress"}
                    type={"ProofOfAddress"}
                    ubo_id={data?.ubo_id}
                    single 
                    supportedFiles={["pdf", "image"]}
                />
            )}

            {!hideButtons && (
                <div className='d-flex w-100 justify-content-between mt-3'>
                    {isAskForInfo ? <div className='flex-1'></div> : <GoBackButton loading={loading} onClick={() => setStep(2)} />}
                    <div className='d-flex'>
                        {iframeVisible && (
                            <PrimaryButton
                                onClick={handleDownloadOnlineClick}
                                className="me-2"
                            >
                                Download Online
                            </PrimaryButton>
                        )}
                        {iframeVisible && (
                            <PrimaryButton
                                onClick={handleUploadOfflineClick}
                                className="me-2"
                            >
                                Upload Offline
                            </PrimaryButton>
                        )}

                        <PrimaryButton
                            loading={loading}
                            className={"px-3"}
                            onClick={handleSubmit}
                        >
                            {loading ? <ThreeBounce color="white" size={8} /> : <>{"Submit"}</>}
                        </PrimaryButton>
                    </div>
                </div>
            )}
        </div>
    );
};

export const InputLabel = ({ label, required, labelStyle }) => (
    <div style={{ minWidth: "120px", fontSize: 16, margin: "5px 0px 0px 5px", fontWeight: "500", ...labelStyle }}>
        {label}
        {required && <font color="red">*</font>}
    </div>
);

export default ProofOfAddressDocument;
