import { ThreeBounce } from "better-react-spinkit";
import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Logo from "../../assets/images/Logo.svg";
import { InputField } from "../../components";
import { UserContext } from "../../store/context/UserContext";
import OtpInput from "./OtpInput";
import "./LoginCredentials.scss";
import { validateEmail } from "./Auth";
import _ from "lodash";
// import LanguagePicker from "../../components/LanguagePicker/LanguagePicker";
import { useTranslation } from "../../store/context/TranslationContext";

const LoginCredentials = (props) => {

  const { userContent, populateUserContent } = useContext(UserContext);
  const {t} = useTranslation()

  const {
    getOTP,
    data, 
    hasErr,
    setData,
    loading,
    errorStatus,
    isCpanelLogin,
    isUser,
    form,
    errorMessage,
    getCaptcha,
    imageURL,
    selecteToggle,
    captchaLoading,
  } = props;

  const loginData = useRef(
    isCpanelLogin
      ? {
        email: "",
        password: "",
        captchaQuery: "",
      }
      : {
        authType: "password",
        countryCode: 91,
        password: "",
        language: "en",
        phone: null,
        email: "",
        emailPhone: "",
        captchaQuery: "",
        captcha_id: " ",
      }
  );

  useEffect(() => {
    document.body.className = "authentication-bg";
    const fetch = async () => {
      if (isCpanelLogin || form?.type == "kyc") getCaptcha();
      else {
        await populateUserContent();
     
        loginData.current = {
          ...loginData.current,
          countryCode: userContent?.countryCode,
        };
      }
    };
    fetch();
    getCaptcha();

    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {
    const myBtn = document.getElementById("captcha");
    if (errorStatus) {
      myBtn.click();
    }
  }, [errorStatus]);

  return (
    <React.Fragment>
      <div className="">
        <div className="w-100 pt-sm-3 ">
          <Container className="d-flex flex-column justify-content-center w-100 p-0">
            <Row className="mb-5 mt-3 d-flex mx-auto align-items-center w-100 justify-content-center">
              <Col className="p-3 d-flex mx-auto align-items-center w-100 justify-content-center" xs={6}>
                <Link to="#" className="d-flex mx-auto align-items-center w-100 justify-content-center">
                  <img style={{ height: "60px" }} src={Logo} alt="fv-bank" className="" />
                </Link>
              </Col>
              {/* {form?.name == "Individual Plus" ?   <div className='language-picker-auth'><LanguagePicker /></div> : ""} */}
            </Row>
            <Row
              className="d-flex mx-auto align-items-center w-100 justify-content-center"
              style={{
                maxWidth: "600px",
              }}
            >
              <Col className="flex-1 mx-6" md={8} lg={6} xl={6}>
                <Card style={{ borderRadius: "20px", border: "1px solid #C6F3FF" }} className="p-3  w-100">
                  <CardBody className="shadow-none w-100 ">
                    <h3 className="align-items-center justify-content-center d-flex pb-4 " style={{ color: "#1678AE", fontSize: "40px" }}>
                      {t(form?.name)}
                    </h3>
                    <div>

                      <div className="form-horizontal "  >
                        <div className="text-center mt-2">
                          <div className="">
                            <div className="d-flex align-items-center" >
                              <InputField
                                value={data?.email}
                                label={t("Email Address")}
                                placeholder={t("user@gmail.com")}
                                type="email"
                                showErr={hasErr}
                                isDisabled={form?.type == "kyc"}
                                onChange={(v) => {
                                  const lowerCaseEmail = _.toLower(v);
                                  setData({ ...data, email: lowerCaseEmail });
                                  loginData.current = {
                                    ...loginData.current,
                                    email: lowerCaseEmail,
                                  };
                                }}
                                inputFieldStyle={{ backgroundColor: "#F1FAFC", borderRadius: "14px", border: "1px solid #C6F3FF" }}
                                className="col-12"
                                error={!validateEmail(data?.email) ? t("Please enter valid email") : ""}
                              />
                            </div>
                            <div className="d-flex flex-column flex-sm-row align-items-center mt-2 " style={{}}>
                              <Col
                                style={{
                                  border: "1px solid #C6F3FF",
                                  borderRadius: "14px",
                                  padding: "5px",
                                  backgroundColor: "#F1FAFC"
                                }}
                                className=" w-100 my-3 d-flex align-items-center   m-0 "
                              >
                                {captchaLoading ? (
                                  <div style={{ flex: 1 }}>
                                    <ThreeBounce size={15} color="#5fabd5" />
                                  </div>
                                ) : <img
                                  className="col-10"
                                  src={`data:image/jpeg;base64,${imageURL}`}
                                  alt=""
                                  height={35}
                                  style={{
                                    marginLeft: "auto",
                                    marginTop: "3px",
                                    objectFit: "contain",
                                  }}
                                />}
                                <div className=" d-flex col-2">
                                  <i
                                    id="captcha"
                                    onClick={() => getCaptcha()}
                                    style={{
                                      fontSize: "25px",
                                      cursor: "pointer",
                                    }}
                                    className="uil-redo rounded-right me-md-0 me-2  "
                                  />
                                </div>
                              </Col>
                              <Col className="flex-1 m-0 ms-1 p-0 col-md-6 col-12">
                                <OtpInput
                                  value={data?.captchaQuery}
                                  onChange={(v) => {
                                    setData({ ...data, captchaQuery: v });
                                    loginData.current = {
                                      ...loginData.current,
                                      captchaQuery: v,
                                    };
                                  }}
                                  error={
                                    data?.captchaQuery?.length === 0
                                      ? "Please enter Captcha"
                                      : ""
                                  }
                                  className="flex-1 mb-1 px-0"
                                  style={{ borderRadius: "14px", border: "1px solid #C6F3FF", height: "50px", }}
                                />
                              </Col>
                            </div>
                            {errorMessage?.captchaQuery ? (
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: "30px",
                                  fontSize: "10px",
                                }}
                              >
                                {errorMessage?.captchaQuery}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mt-4 mb-3 w-100 ">
                          <Button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            style={{
                              margin: "auto",
                              borderRadius: "14px",
                              padding: "7px 5px",
                              backgroundColor: " #1678AE",
                              border: "1px solid #C6F3FF"
                            }}
                            disabled={loading || (selecteToggle && (!data?.captchaQuery || !data?.email || data?.captchaQuery?.length != 4))}
                            type="submit"
                            onClick={getOTP}
                          >
                            {loading ? (
                              <ThreeBounce size={20} color="#FFFFFF" />
                            ) : (
                              <div style={{ fontSize: "20px", }}
                              > {t("Send OTP")}</div>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-3 text-center">
                  <p className="font-size-16">
                    Version 1.0.0
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div >
    </React.Fragment >
  );
};

export default LoginCredentials;
