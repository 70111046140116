import React, { useContext, useState } from 'react'
import ProofOfDocument from '../../BusinessLiteForms/components/ProofOfDocument'
import { DialogContext } from '../../../../../store/context/DialogContext'
import { doGET } from '../../../../../util/HttpUtil'
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup'

const Documents = ({ isAskForInfo, remark, recordId, asLabel, setHasError, handleUpdate, data, pageNum, loading, step, decrementStep, handleSubmit }) => {
    const { showError } = useContext(DialogContext)
    const [documentErrors, setDocumentErrors] = useState({
        businessPlan: '',
        shareholders: '',
        principleProofOfAddress: '',
        SourceOfBusFundsProof: '',
        corporateStructureChart: '',
        organizationalChart: '',
        license: '',
        amlProgram: '',
        amlReview: '',
        compliancePolicy: '',
        flowOfFundsDiagram: '',
        businessAgreement: ''
});

const [docLoading, setDocloading]= useState(false);
    const checkIsDocumentUploaded = async (field) => {
        try {
            const response = await doGET(`/api/application-doc/grid?form_id=${recordId}&type=${field}`);
            if (response?.status === 200) {
                if (response?.data?.rows[0]?.attachment?.urls?.length) {
                    return true
                }
            }
        } catch (error) {
            showError(error)
        }
        return false
    }

    const validateDocuments = async () => {
        const errors = { ...documentErrors };
        let hasAnyError = false;
        let firstErrorMessage = null;
    
        const documentsToCheck = [
            { 
                field: 'businessPlan', 
                condition: !!data?.hasBusinessPlan,
                errorMsg: 'Please upload your business plan.' 
            },
            { 
                field: 'shareholders', 
                errorMsg: 'Please upload a register of shareholders, members or partners.' 
            },
            { 
                field: 'principleProofOfAddress', 
                errorMsg: 'Please upload a proof of address for the principal place of business(operating address). (Utility bill or bank statement issued with in the last 90 days, rental contract or lease agreement)' 
            },
            { 
                field: 'SourceOfBusFundsProof', 
                errorMsg: 'Please upload a source of funds, like a bank statement, trading account, invoice or contract.' 
            },
            {
                field: "corporateStructureChart",
                errorMsg: 'Please provide a corporate structure chart which shows name, country of formation, year of incorporation and nature of business of any parent, subsidiary and/or affiliate' 
            },
            {
                field: "organizationalChart",
                errorMsg: 'Please provide the organizational chart (Organogram)' 
            },
            { 
                field: 'license', 
                condition: !!data?.hasMsbLicense || !!data?.hasLicense,
                errorMsg: 'Please upload a copy of your license or exemption.' 
            },
            { 
                field: 'amlProgram', 
                condition: !!data?.hasAmlProgram,
                errorMsg: 'Please upload your AML/CFT Program (policies and procedures).' 
            },
            { 
                field: 'amlReview', 
                condition: !!data?.amlCftReviewed,
                errorMsg: 'Please upload your independent AML/CFT review.' 
            },
            { 
                field: 'compliancePolicy', 
                condition: !!data?.hasComplaintsPolicy,
                errorMsg: 'Please upload your complaints policy.' 
            },
            { 
                field: 'businessAgreement', 
                condition: !!data?.dealsInCryptocurrency,
                errorMsg: 'Please upload your business agreement or terms and conditions.' 
            },
            {
                field: 'flowOfFundsDiagram',
                errorMsg: 'Please upload a flow of funds diagram that integrates the role of FV Bank. ' 
            }
        ];
    
        setDocloading(true);
        for (const doc of documentsToCheck) {
            const condition = doc?.condition ?? true;
    
            if (condition) {
                try {
                    const isUploaded = await checkIsDocumentUploaded(doc.field);
                    if (!isUploaded) {
                        hasAnyError = true;
                        errors[doc.field] = doc.errorMsg;
    
                        if (!firstErrorMessage) {
                            firstErrorMessage = doc.errorMsg;
                        }
                    } else {
                        errors[doc.field] = '';
                    }
                } catch (error) {
                    hasAnyError = true;
                    errors[doc.field] = `Error checking document: ${doc.field}`;
                    if (!firstErrorMessage) {
                        firstErrorMessage = `Error checking document: ${doc.field}`;
                    }

                }
             
            }
        }
        
    

        if (firstErrorMessage) {
            setDocloading(false);
            showError(firstErrorMessage);
        }
    
        setDocumentErrors(errors);
        return !hasAnyError;
    };
    
    
    const handleSubmitDoc = async () => {
        const isValid = await validateDocuments();
        if (isValid) {
            handleSubmit({isLastStep : true});
        }
    };
    

    return (
        <>
            <div className='mb-3'>
                {
                    !!data?.hasBusinessPlan ? (
                        <ProofOfDocument
                            isAskForInfo={isAskForInfo}
                            required
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload your business plan."}
                            field={"businessPlan"}
                            showExpiry={false}
                            type={"businessPlan"}
                            error={documentErrors.businessPlan}
                        />
                    ) : null
                }

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please upload a register of shareholders, members or partners."}
                    field={"shareholders"}
                    required={true}
                    type={"shareholders"}
                    error={documentErrors.shareholders}
                />

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    required={true}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please upload a proof of address for the principal place of business(operating address). (Utility bill or bank statement issued with in the last 90 days, rental contract or lease agreement)"}
                    field={"principleProofOfAddress"}
                    type={"principleProofOfAddress"}
                    error={documentErrors.principleProofOfAddress}
                />

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    required={true}
                    label={"Please upload a source of funds, like a bank statement, trading account, invoice or contract."}
                    field={"SourceOfBusFundsProof"}
                    type={"SourceOfBusFundsProof"}
                    
                    error={documentErrors.SourceOfBusFundsProof}
                />

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please provide a corporate structure chart which shows name, country of formation, year of incorporation and nature of business of any parent, subsidiary and/​or affiliate"}
                    field={"corporateStructureChart"}
                    type={"corporateStructureChart"}
                    error={documentErrors.corporateStructureChart}
                />

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please provide the organizational chart (Organogram)"}
                    field={"organizationalChart"}
                    type={"organizationalChart"}
                    error={documentErrors.organizationalChart}
                />

                {
                    !!data?.hasMsbLicense || !!data?.hasLicense ? (
                        <ProofOfDocument
                            required={!!data?.hasMsbLicense || !!data?.hasLicense }
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload a copy of your license or exemption."}
                            field={"license"}
                            type={"license"}
                            error={documentErrors.license}
                        />
                    ) : null
                }

                {
                    !!data?.hasAmlProgram ? (
                        <ProofOfDocument
                            required
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload your AML/​CFT Program (policies and procedures)."}
                            field={"amlProgram"}
                            type={"amlProgram"}
                            error={documentErrors.amlProgram}
                        />
                    ) : null
                }

                {
                    !!data?.amlCftReviewed ? (
                        <ProofOfDocument
                            required
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload your independent AML/CFT review."}
                            field={"amlReview"}
                            type={"amlReview"}
                            error={documentErrors.amlReview}
                        />
                    ) : null
                }

                {
                    !!data?.hasComplaintsPolicy ? (
                        <ProofOfDocument
                            required
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload your complaints policy."}
                            field={"compliancePolicy"}
                            type={"compliancePolicy"}
                            error={documentErrors.compliancePolicy}
                        />
                    ) : null
                }

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please upload a flow of funds diagram that integrates the role of FV Bank."}
                    field={"flowOfFundsDiagram"}
                    type={"flowOfFundsDiagram"}
                    error={documentErrors.flowOfFundsDiagram}
                />

                {
                    !!data?.dealsInCryptocurrency ? (
                        <ProofOfDocument
                            required
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload your business agreement or terms and conditions."}
                            field={"businessAgreement"}
                            type={"businessAgreement"}
                            error={documentErrors.businessAgreement}
                        />
                    ) : null
                }
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading || docLoading}
                asLabel={asLabel}
                limit={step}
                onNextClick={() => handleSubmitDoc()}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default Documents;