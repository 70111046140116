export const jsonFields = [
    {
        "label": "First Name",
        "field": "firstName",
        "defaultValue": "",
        "placeholder": "Enter your First Name",
        "type": "string",
        "uiType": "input",
        "regex": "^[a-zA-Z0-9\\s\\.\\,\\-\\']{2,100}$",
        "uiRegex": "^[a-zA-Z\\s]$",
        "error": "Please enter a valid first name.",
        "validator": "nameValidator",
        "errorTypes": {
            "required": "Please enter your First Name",
            "length": "Your First Name should be between 2 and 50 characters.",
            "format": "Please enter a valid First Name with only alphabetic characters."
        },
        "showIf": {
            "field": "check",
            "condition": "==",
            "value": true
        },
        "max": 50,
        "min": 2,
        "required": true
    },
    {
        "label": "Country",
        "field": "country",
        "placeholder": "Select Country",
        "type": "string",
        "uiType": "select",
        "options": [
            { "label": "India", "value": "India" },
            { "label": "United States", "value": "US" },
            { "label": "Canada", "value": "Canada" }
        ],
        "error": "Please select a country.",
        "validator": null,
        "required": true,
        "showIf": {
            "field": "check",
            "condition": "==",
            "value": true
        }
    },
    {
        "label": "Detailed Notes",
        "field": "detailedNotes",
        "defaultValue": null,
        "placeholder": "Enter detailed notes",
        "type": "text",
        "uiType": "textarea",
        "error": "Please provide detailed notes.",
        "validator": null,
        "errorTypes": {
            "required": "Detailed notes are required",
            "length": "Notes should be between 20 and 500 characters."
        },
        "max": 500,
        "min": 20,
        "required": false
    },
    {
        "label": "Employment Status",
        "field": "employmentStatus",
        "question": "Are you currently employed?",
        "type": "boolean",
        "uiType": "radioBoolean",
        "options": [
            { "label": "Yes", "value": true },
            { "label": "No", "value": false }
        ],
        "error": "Please select an option for Employment Status",
        "validator": null,
        "required": true
    },
    {
        "label": "Type of Custody",
        "field": "custodyType",
        "question": "What type of custody do you offer?",
        "type": "string",
        "uiType": "radioMultiple",
        "options": [
            { "label": "Custodial Wallet", "value": "Custodial Wallet" },
            { "label": "Self Custody", "value": "Self Custody" },
            { "label": "Not Applicable", "value": "Not Applicable" }
        ],
        "error": "Please select a custody type.",
        "validator": null,
        "required": true,
        "showIf": {
            "field": "check",
            "condition": "==",
            "value": true
        }
    },
    {
        "label": "Skills",
        "field": "skills",
        "placeholder": "Select your skills",
        "type": "array",
        "uiType": "checkboxMultiple",
        "options": [
            { "label": "Programming", "value": "Programming" },
            { "label": "Design", "value": "Design" },
            { "label": "Marketing", "value": "Marketing" }
        ],
        "error": "Please select at least one skill.",
        "validator": null,
        "required": false
    },
    {
        "label": "Age",
        "field": "age",
        "placeholder": "Enter your age",
        "type": "number",
        "uiType": "input",
        "error": "Please enter a valid age.",
        "validator": "ageValidator",
        "errorTypes": {
            "required": "Age is required",
            "format": "Age must be a number between 18 and 65."
        },
        "required": true
    },
    {
        "label": "Password",
        "field": "password",
        "placeholder": "Enter a strong password",
        "type": "password",
        "uiType": "input",
        "error": "Please enter a valid password.",
        "validator": "passwordStrengthValidator",
        "errorTypes": {
            "required": "Password is required",
            "format": "Password must be strong enough."
        },
        "required": true
    },
    {
        "label": "Phone Number",
        "field": "phone",
        "placeholder": "Enter your phone number",
        "type": "string",
        "uiType": "input",
        "error": "Please enter a valid phone number.",
        "validator": "phoneValidator",
        "errorTypes": {
            "required": "Phone number is required",
            "format": "Phone number must be a 10-digit number."
        },
        "required": true
    },
    {
        "label": "Email",
        "field": "email",
        "placeholder": "Enter your email",
        "type": "string",
        "uiType": "input",
        "error": "Please enter a valid email.",
        "validator": "emailValidator",
        "errorTypes": {
            "required": "Email is required",
            "format": "Please enter a valid email address."
        },
        "required": true
    },
    {
        "label": "Appointment Date",
        "field": "appointmentDate",
        "placeholder": "Select a date",
        "type": "date",
        "uiType": "input",
        "error": "Please select a valid date.",
        "validator": "dateValidator",
        "errorTypes": {
            "required": "Date is required",
            "format": "Date cannot be in the past."
        },
        "required": true
    },
    {
        "label": "Date of Birth",
        "field": "dob",
        "placeholder": "dd/mm/yyyy",
        "type": "date",
        "uiType": "date",
        "error": "Please select a valid date of birth.",
        "validator": "dateValidator",
        "errorTypes": {
            "required": "Date of birth is required",
            "format": "Date cannot be in the future."
        },
        "minDate": "1913-01-03", // 110 years ago
        "maxDate": new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().split('T')[0], // 18 years ago
        "required": true
    },
    {
        "label": "Estimated Start Date",
        "field": "operateEstDate",
        "placeholder": "dd/mm/yyyy",
        "type": "date",
        "uiType": "date",
        "error": "Please select a valid start date.",
        "validator": "dateValidator",
        "errorTypes": {
            "required": "Start date is required",
            "format": "Date cannot be in the past."
        },
        "minDate": new Date().toISOString().split('T')[0], // today
        "maxDate": new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString().split('T')[0], // 5 years from today
        "required": true
    }


];
