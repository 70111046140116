import React from 'react';
import { InputLabel } from '../../components/IndividualLite/UserDetailsForm';
import { Col, Row } from 'reactstrap';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import { WrapperField } from '../../../../../components/InputField/WrapperField';


const ComplianceDetails = ({ asLabel,editMode,  data, handleChange, pageNum, loading, step, decrementStep, handleSubmit, bPrimeJSON, showErr, setErrors }) => {
    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Compliance</h2>
                <InputLabel label="Name of Compliance Officer or equivalent" required labelStyle={{ fontSize: 15 }} />

                <Row>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["complianceOfficerFirstName"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            className="my-3"
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, complianceOfficerFirstName: errorMsg }))}
                        />
                    </Col>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["complianceOfficerLastName"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            className="my-3"
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, complianceOfficerLastName: errorMsg }))}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col className="col-xl-6 col-12">

                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["complianceOfficerEmail"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            className="my-3"
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, complianceOfficerEmail: errorMsg }))}
                        />
                    </Col>

                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["complianceOfficerPhone"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            className="my-3"
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, complianceOfficerPhone: errorMsg }))}
                        />
                    </Col>
                </Row>


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasAmlProgram"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasAmlProgram: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["amlCftReviewed"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, amlCftReviewed: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["upcomingAmlReview"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, upcomingAmlReview: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasAmlPolicy"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasAmlPolicy: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["numberOfComplianceStaff"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, numberOfComplianceStaff: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["monitoringTools"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, monitoringTools: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasComplaintsPolicy"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasComplaintsPolicy: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasCustomerAgreement"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasCustomerAgreement: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["noCustomerAgreementExplanation"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, noCustomerAgreementExplanation: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasSanctionsProgram"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasSanctionsProgram: errorMsg }))}
                />


            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel || editMode}
                limit={10}
                onNextClick={() => handleSubmit({ isLastStep: false })}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default ComplianceDetails;
