import React, { useEffect, useState } from 'react';
import { FaRegUserCircle, FaDollarSign, FaMobileAlt, FaRegListAlt, FaTools } from 'react-icons/fa';
import '../IndividualLite.scss'; // Ensure the SCSS file is correctly imported
import Logo from "../../../../assets/images/Logo.svg";
import { useNavigate } from 'react-router-dom';
import ButtonGroup from './components/ButtonGroup';

const ApplyBusinessLiteContent = () => {
    const navigate = useNavigate();

    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const queryParams = urlObject.search; // Get the query string

    const t = (v) => v

    const [sz, setSz] = useState(20);

    return (
        <div className="apply-individual-lite">
            <div className="fv_indi-container form-content-tabs">
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img
                        src={Logo}
                        alt="fv-bank"
                        height="50"
                        className="logo logo-dark"
                    />
                    <div className="fv_indi-title">Business Plus</div>
                </div>

                <ul className="fv_indi-list">
                    <li className="fv_indi-item">
                        <div className='fv_indi-icon-container'>        <FaRegUserCircle size={sz} className="fv_indi-icon" />  </div>
                        <div className=''>{t('Offers reduced or no monthly maintenance fees to keep banking cost-effective for small enterprises.')}</div>
                    </li>
                    <li className="fv_indi-item">
                        <div className='fv_indi-icon-container'>  <FaDollarSign size={sz} className="fv_indi-icon" /> </div>
                        <div className=''>  {t('Includes essential features such as online banking, a business debit card, and mobile app access.')}</div>

                    </li>
                    <li className="fv_indi-item">
                        <div className='fv_indi-icon-container'> <FaMobileAlt size={sz} className="fv_indi-icon" /> </div>
                        <div className=''>{t('May include transaction limits appropriate for small business needs, minimizing costs and complexity.')}</div>

                    </li>
                    <li className="fv_indi-item">
                        <div className='fv_indi-icon-container'>  <FaRegListAlt size={18} className="fv_indi-icon" /></div>
                        <div className=''>{t('Streamlined application process and user-friendly account management tools.')}</div>
                    </li>
                    <li className="fv_indi-item">
                        <div className='fv_indi-icon-container'> <FaTools size={18} className="fv_indi-icon" /></div>
                        <div className=''> {t('Designed to support the daily financial operations of small businesses, providing ease and efficiency.')}</div>
                    </li>
                </ul>


                <div className='mt-4'>
                    <ButtonGroup hideBackButton onNextClick={() => { navigate(`/business-plus/login${queryParams}`) }} onBackClick={() => { navigate(-1) }} step={2} asLabel={false} loading={false} limit={10} />
                </div>
            </div>
        </div>

    );
};

export default ApplyBusinessLiteContent;