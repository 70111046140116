import React from 'react'
import Logo from "../../../../../assets/images/Logo.svg";

const ThankYou = ({ type }) => {
    return (
        <div className='d-flex flex-column  justify-content-center  align-items-center  '>
            <div style={{ fontSize: "30px", lineHeight: 2, marginBottom: 10 }}>
                Thank you for submitting your application.
            </div>
            <div style={{ fontSize: "20px" }}>
                We have received the information you have provided us. You will receive further communication from us.
            </div>
            <div style={{ fontSize: "20px" }}>
                Appreciate your interest in upgrading with us.
            </div>
        </div>
    )
}

export default ThankYou