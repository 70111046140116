import React from 'react';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import { WrapperField } from '../../../../../components/InputField/WrapperField';

const FundFamilyOffices = ({ asLabel, editMode,  data, handleChange, pageNum, loading, step, decrementStep, handleSubmit, bPrimeJSON, showErr, setErrors }) => {
    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Funds and Family Offices</h2>
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["distributeFunds"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, distributeFunds: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["institutionTypes"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, institutionTypes: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["institutionTypesOther"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, institutionTypesOther: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["requiresFundLicense"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, requiresFundLicense: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasFundLicense"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasFundLicense: errorMsg }))}
                />

                {/* {!!data?.requiresFundLicense && !!data?.hasFundLicense ? ( */}
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["licenseHolderDetails"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, licenseHolderDetails: errorMsg }))}
                />
                {/* ) : null} */}

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["subsidiesOrSubventions"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, subsidiesOrSubventions: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["assets"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, assets: errorMsg }))}
                />

            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel || editMode}
                limit={10}
                onNextClick={() => handleSubmit({ isLastStep: false })}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default FundFamilyOffices;
