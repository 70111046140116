import React, { useState } from 'react'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

const ConsoleAccordion = ({ steps }) => {
    const [openSteps, setOpenSteps] = useState(steps.map(() => false));
    const toggleStep = (index) => {
        setOpenSteps((prev) => {
            const newOpenSteps = [...prev];
            newOpenSteps[index] = !newOpenSteps[index];
            return newOpenSteps;
        });
    };

    return (
        <div>
            {steps
                .filter(Boolean)
                .map((child, index) => {
                    const isOpen = openSteps[index];
                    return (
                        <div key={index}>
                            <div
                                className='my-3'
                                style={{ fontSize: "16px", fontWeight: "500", cursor: "pointer", display: "flex", alignItems: "center" }}
                                onClick={() => toggleStep(index)}
                            >
                                {isOpen ? <FaChevronDown size={16} style={{ marginRight: "10px" }} /> : <FaChevronRight size={16} style={{ marginRight: "10px" }} />}
                                {`Step: ${index + 1} ${child.props.label}`}
                            </div>
                            {isOpen && child}
                        </div>
                    );
                })}
        </div>
    );
}

export default ConsoleAccordion