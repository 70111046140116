import React from 'react';
import { Button } from 'react-bootstrap';

const StartAgain = ({ type, startAgain }) => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <div style={{ fontSize: "30px", lineHeight: 2, marginBottom: 10 }}>
                You have reached the last page.
            </div>
            <div style={{ fontSize: "20px" }}>
                Please start again to update information.
            </div>
            <Button onClick={startAgain} className='py-2 mt-2 rounded-3' variant='primary'>
        Start Again 
            </Button>
        </div>
    );
}

export default StartAgain;
