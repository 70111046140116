import React from 'react';
import TextArea from 'antd/es/input/TextArea';
import "../../components/InputField/style.css";

const CustomTextArea = ({
    labelStyle,
    placeholder,
    value,
    onChange,
    error,
    showErr,
    label,
    className,
    required,
    isDisabled
}) => (
    <div className={className}>
        {label && <label style={{ ...labelStyle, margin: "0px 0px 5px 5px", fontWeight: "500", textAlign: "left", fontSize : "15px" }}>
            {label}
            {!!required ? <font color="red">*</font> : ""}
        </label>}
        <TextArea
            disabled={isDisabled}
            className='fvi_text_area'
            placeholder={placeholder}
            value={value}
            onChange={(v) => onChange(v.target.value)}
        />
        {showErr && error && <div className="inputfield__error">{error}</div>}
    </div>
);

export default CustomTextArea;