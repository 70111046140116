import React, { useMemo } from 'react';
import { InputField, SelectField } from '..';
import CustomTextArea from './CustomTextArea';
import { QuestionRadioGroup } from '../../pages/Form/forms/components/IndividualLite/DeclarationForm';
import CheckBoxFieldMutiple from '../CheckBox/CheckBoxFieldMutiple';
import { readValue, setValue, shouldShowField } from './helper';
import AntDateSelect from '../DateSelects/AntDateSelect';
import { QuestionRadioGroupMultiple } from '../../pages/Form/forms/BusinessLiteForms/components/Page6';
import AmountField from './AmountField';



export const customValidators = {
  ageValidator: (dataObj, path, value) => {
    if (value < 18 || value > 65) {
      return "Age must be between 18 and 65.";
    }
    return "";
  },

  passwordStrengthValidator: (dataObj, path, value) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[!@#\$%\^\&*\)\(+=._-]/.test(value);
    if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar) {
      return "Password must contain uppercase, lowercase, number, and special character.";
    }
    return "";
  },

  phoneValidator: (dataObj, path, value) => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(value)) {
      return "Phone number must be a 10-digit number.";
    }
    return "";
  },
  emailValidator : (dataObj, path, value) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!re.test(value)) {
      return "Please enter a valid email address.";
    }
    return "";
  },
  dateValidator: (dataObj, path, value) => {
    const inputDate = new Date(value);
    const currentDate = new Date();
    if (inputDate < currentDate) {
      return "Date cannot be in the past.";
    }
    return "";
  },

  annualfinancialStatementValidator: (dataObj, path, value) => {
    return "";
  },

  financialForecastValidator: (dataObj, path, value) => {
    if (dataObj?.hasAnnualStatements == false && value == false) {
      return "We require that you submit a financial forecast in case you do not have financial statements to submit."
    }
    return "";
  },
  licenseHolderValidator:(dataObj, path, value) =>{
    if (dataObj?.requiresFundLicense == false && dataObj?.hasFundLicense == false) {
      return "Please provide the licenses you hold and the jurisdiction."
    }
    return "";

  },
  maxSizeValidator:(dataObj, path, value) =>{
    const MAX_VALUE=2147483647
    if(value>MAX_VALUE){
      return `Value cannot exceed ${MAX_VALUE}`
    }
    if (value < 0) {
      return `Value should be greater than 0`
    }
    return ""
  },
  finCenValidator: (dataObj, path, value) =>{
    if(dataObj?.hasUsCustomers==true && value==false){
      return "Are you registered with FinCen? must be Yes."
    }
    return ""
  }
};

export const WrapperField = ({ jsonField, dataObj, onChange, showError, onError, className, disabled }) => {


  const validateField = useMemo(() => {
    return (value) => {
      let error = "";
      if (jsonField?.required && (value === undefined || value === null || value === "" || value?.length==0)) {
        error = jsonField?.errorTypes?.required || jsonField.error || "This field is required.";
      }else if (jsonField?.min && value?.length < jsonField.min) {
        error = jsonField?.errorTypes?.length || `Minimum length is ${jsonField?.min}.`;
      } else if (jsonField?.regex && !new RegExp(jsonField?.regex).test(value)) {
        error = jsonField?.errorTypes?.format || jsonField.error || "Invalid format.";
      } else if (jsonField.max && value?.length > jsonField.max) {
        error = jsonField?.errorTypes?.length || `Maximum length is ${jsonField?.max}.`;
      }
      // Custom validator logic
      if (jsonField.validator && customValidators?.[jsonField.validator]) {
        const customError = customValidators[jsonField.validator](dataObj, jsonField.field, value);
        if (customError) {
          error = customError;
        }
      }
      return error;
    };
  }, [jsonField, dataObj]);

  const parseDate = (dateStr) => {
    return dateStr ? new Date(dateStr) : null;
  };

  const handleChange = (value) => {
    const error = validateField(value);
    if (error) {
      onError(error);
    } else {
      onError(null);
    }
    onChange(setValue(dataObj, jsonField?.field, value));
  };


  if (!shouldShowField(dataObj, jsonField?.showIf)) {
    if (readValue(dataObj, jsonField?.field) !== jsonField?.defaultValue) {
      onChange(setValue(dataObj, jsonField?.field, jsonField?.defaultValue))
    }
    return null;
  }

  switch (jsonField?.uiType) {
    case "amountField" : 
    return (
      <AmountField
        label={jsonField?.label}
        isDisabled={disabled}
        placeholder={jsonField?.placeholder}
        onChange={(v) => handleChange(v)}
        required={jsonField?.required}
        value={readValue(dataObj, jsonField?.field)}
        error={validateField(readValue(dataObj, jsonField?.field))}
        showErr={showError}
        className={className}
      />
    )
    case "input":
      return (
        <InputField
          label={jsonField?.label}
          isDisabled = {disabled}
          placeholder={jsonField?.placeholder}
          onChange={(v) => handleChange(v)}
          required={jsonField?.required}
          value={readValue(dataObj, jsonField?.field)}
          error={validateField(readValue(dataObj, jsonField?.field))}
          showErr={showError}
          className={className}
          maxLength={jsonField?.max}
          regex={new RegExp(jsonField?.uiRegex)}
        />
      );
    case "textarea":
      return (
        <CustomTextArea
          label={jsonField?.label}
          placeholder={jsonField?.placeholder}
          required={jsonField?.required}
          onChange={(v) => handleChange(v)}
          value={readValue(dataObj, jsonField?.field)}
          error={validateField(readValue(dataObj, jsonField?.field))}
          showErr={showError}
          className={className}
          isDisabled={disabled}
        />
      );
    case "select":
      return (
        <SelectField
          label={jsonField?.label}
          placeholder={jsonField?.placeholder || "Select an option"}
          data={jsonField?.options || []}
          value={readValue(dataObj, jsonField?.field)}
          required={jsonField?.required}
          onChange={(v) => handleChange(v?.value)}
          error={validateField(readValue(dataObj, jsonField?.field))}
          showErr={showError}
          isDisabled={disabled ||  jsonField?.isDisabled || false}
          className={className}
        />
      );
    case "checkboxMultiple":
      return (
        <CheckBoxFieldMutiple
          label={jsonField?.label}
          labelStyle={jsonField?.labelStyle || { fontSize: 15 }}
          data={jsonField?.options || []}
          value={readValue(dataObj, jsonField?.field) || []}
          onChange={(v) => handleChange(v)}
          error={validateField(readValue(dataObj, jsonField?.field))}
          showErr={showError}
          isDisabled={disabled || jsonField?.isDisabled || false}
          className={className}
          required={jsonField?.required || false}
        />
      );
    case "radioBoolean":
      return (
        <QuestionRadioGroup
          question={jsonField?.label}
          field={jsonField?.field}
          data={dataObj}
          handleChange={(field, v) => handleChange(v)}
          style={jsonField?.style || { fontSize: 15 }}
          error={validateField(readValue(dataObj, jsonField?.field))}
          hasError={showError}
          isDisabled={disabled || jsonField?.isDisabled || false}
          defaultValue={jsonField?.defaultValue}
          required={jsonField?.required || false}
        />
      );
    case "radioMultiple":
      return (
        <QuestionRadioGroupMultiple
          question={jsonField?.label}
          field={jsonField?.field}
          data={dataObj}
          handleChange={(field, v) => handleChange(v)}
          style={jsonField?.style || { fontSize: 15 }}
          options={jsonField?.options || []}
          error={validateField(readValue(dataObj, jsonField?.field))}
          showErr={showError}
          isDisabled={disabled || jsonField?.isDisabled || false}
          required={jsonField?.required || false}
        />
      );

    case "date":
      return (
        <AntDateSelect
          placeholder={jsonField?.placeholder || "dd/mm/yyyy"}
          className={className || "col-sm-6 mb-0"}
          format="dd/mm/yyyy"
          label={jsonField?.label}
          value={readValue(dataObj, jsonField?.field)}
          onChange={(v) => handleChange(v)}
          error={validateField(readValue(dataObj, jsonField?.field))}
          showErr={showError}
          isDisabled={disabled || jsonField?.isDisabled || false}
          minDate={parseDate(jsonField?.minDate)}
          maxDate={parseDate(jsonField?.maxDate)}
          required={jsonField?.required || false}
        />
      );
    default:
      return null;
  }
};