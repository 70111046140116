const showIfValidator = {
    saValidator: (dataObj) => {
        if (dataObj?.depositLocations?.includes("South_America") || dataObj?.paymentLocations?.includes("South_America")) {
            return true
        }
        return false
    },
    hasAnnualStatementValidator: (dataObj) => {
        if (dataObj?.hasAuditStatements == false) {
            return true
        }
        return false
    },
    hasFinancialForecastValidator: (dataObj) => {
        if (dataObj?.hasAnnualStatements == false) {
            return true
        }
        return false
    },
}
export const shouldShowField = (data, ifCondition) => {

    if (!ifCondition) return true;
    if(ifCondition?.validator && showIfValidator?.[ifCondition?.validator]){
        return showIfValidator?.[ifCondition?.validator](data)
    }
    const { field, condition, value } = ifCondition;
    const fieldValue = readValue(data, field)

    switch (condition) {
        case "==":
        case "===":
        case "equals":
            return fieldValue === value;
        case "!=":
        case "!==":
        case "notEquals":
            return fieldValue !== value;
        case "includes":
            return Array.isArray(fieldValue) && fieldValue.includes(value);
        case "notIncludes":
            return Array.isArray(fieldValue) && !fieldValue.includes(value);
        default:
            return true;
    }

}

export const readValue = (newObj, fieldPath) => {
    if (!newObj) {
        return null;
    }
    if (!fieldPath) {
        return newObj;
    }
    const dot = fieldPath.indexOf(".");
    if (dot === -1) {
        if (newObj !== null && typeof newObj === "object") {
            return newObj[fieldPath];
        }
        const fieldPartAsInteger = parseInt(fieldPath);
        if (!isNaN(fieldPartAsInteger) && typeof fieldPartAsInteger === 'number') {
            return readValue(newObj[fieldPartAsInteger], null);
        }
        return newObj[fieldPath];
    } else {
        let firstPart = fieldPath.substring(0, dot);
        let secondPart = fieldPath.substring(dot + 1);

        const firstPartAsInteger = parseInt(firstPart);
        if (!isNaN(firstPartAsInteger) && typeof firstPartAsInteger === 'number') {
            return readValue(newObj[firstPartAsInteger], secondPart);
        }
        return readValue(newObj[firstPart], secondPart);
    }
}

export const setValue = (newObj, fieldPath, newValue) => {
    const dot = fieldPath.indexOf(".");
    if (dot === -1) {
        newObj[fieldPath] = newValue;
    } else {
        let firstPart = fieldPath.substring(0, dot);
        const secondPart = fieldPath.substring(dot + 1);
        if (!newObj[firstPart]) {
            const arrayDot = secondPart.indexOf(".");
            const arrayFirstPart = secondPart.substring(0, arrayDot);
            const fieldPartAsInteger = parseInt(arrayFirstPart);
            if (!isNaN(fieldPartAsInteger) && typeof fieldPartAsInteger === 'number') {
                newObj[firstPart] = [];
            } else {
                newObj[firstPart] = {};
            }
        }
        setValue(newObj[firstPart], secondPart, newValue);
    }
    return { ...newObj }
}
