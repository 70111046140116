import React from 'react';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import _ from 'lodash';
import { WrapperField } from '../../../../../components/InputField/WrapperField';



const AssetsInvolvement = ({ asLabel, editMode,  data, handleChange, pageNum, loading, step, decrementStep, handleSubmit, bPrimeJSON, showErr, setErrors }) => {
    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Cryptocurrency and Digital Assets Involvement</h2>

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["dealsInCryptocurrency"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, dealsInCryptocurrency: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["requiresLicense"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, requiresLicense: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasLicense"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasLicense: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["cryptocurrencyBusinessModel"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, cryptocurrencyBusinessModel: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["cryptocurrencyBusinessModelOther"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, cryptocurrencyBusinessModelOther: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["custodiedWallets"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, custodiedWallets: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["cryptocurrencyTypes"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, cryptocurrencyTypes: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["cryptocurrencyTypesOther"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, cryptocurrencyTypesOther: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["cryptoCustodyLicenses"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, cryptoCustodyLicenses: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["analyticsServices"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, analyticsServices: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["analyticsServicesOther"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, analyticsServicesOther: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["cryptoTransactionScreening"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, cryptoTransactionScreening: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["cryptoComplianceProgram"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, cryptoComplianceProgram: errorMsg }))}
                />
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel || editMode}
                limit={10}
                onNextClick={() => handleSubmit({ isLastStep: false })}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    );
};

export default AssetsInvolvement;
