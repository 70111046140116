import React, { useContext, useEffect, useState } from "react";
import { Button, Row } from "reactstrap";
import { doGET } from "../../util/HttpUtil";
import DocField from "./DocField";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";

const Documents = ({ 
  record_id,
  field = "",
  asLabel,
  module, 
  inline,
  showExpiry,
  getRecordId, 
  hideUpload,
  showCategory,
  ubo_id,
  type,
  formId,
  seq , 
  onChange = () => { }, 
  enableOnline,
  supportedFiles = ["image", "pdf"],
  single = false,
  error = "",
  isAskForInfo
}) => {

  const [documents, setDocuments] = useState([]);
  const { showError } = useContext(DialogContext)
  const{t} =useContext(I18nContext);


  const fetch = async () => {
    if (module && record_id) {
      try {
        const response = await doGET(`/api/file/grid?module=${module}&record_id=${record_id}&${field ? `field=${field}` : ""}`);
        if (response.status === 200) {
          const docs = response.data?.rows;
          setDocuments(docs);
        }
      } catch (error) {
        showError("Error", error)
      }
    }
    else if(record_id){

      try {
        const response = await doGET(`/api/application-doc/grid?rows=-1&form_id=${record_id}&${ubo_id ? `ubo_id=${ubo_id}` : ""}&${seq ? `seq=${seq}` : "" }&${field ? `type=${type}` : ""}`);
        if (response.status === 200) {
          const docs = response.data?.rows;
          setDocuments(docs);
        }
      } catch (error) {
        showError("Error", error)
      }
    }
  };

  const updateDocument = (doc, index, v) => {
    let documentsCopy = [...documents];

    documentsCopy = documentsCopy.map((d, i) => {
      if (i === index) {
        return { ...d, _id: v };
      }
      return d;
    });
    setDocuments(documentsCopy);
  }

  useEffect(() => {
    fetch();
  }, [module, record_id]);

  useEffect(() => {
    if (documents?.length == 0) {
      setDocuments([
        ...documents,
        { attachment: { name: "", info: "", refNo: "", expanded: true, urls: [] }, expanded: true },
      ]);
    }
  }, [documents]);


  return (
    <div>
      <Row>
        {documents?.map((doc, index) => (
          <div className="  col-12 my-3">
            <DocField
              key={index}
              showCategory={showCategory}
              showExpiry={showExpiry}
              ubo_id={ubo_id}
              type={type}
              hideUpload={hideUpload}
              onDelete={() => {
                fetch();
              }}
              onSubmit={() => {
                fetch();
              }}
              asLabel = {asLabel}
              value={doc?._id}
              onChange={(v) => {
                updateDocument(doc, index, v)
                onChange(doc, index, v)
              }}
              field={field}
              data={doc}
              record_id={record_id}
              getRecordId={getRecordId}
              module={module}
              inline={inline}
              formId={formId}
              seq={seq}
              enableOnline={enableOnline}
              supportedFiles={supportedFiles} 
              single={single}
              error={error}
            />
          </div>
        ))}
      </Row>
    </div>
  );
};

export default Documents;
