import React, { useState, useEffect, useContext } from "react";
import { doGET, doPUT, doPOST } from "../../util/HttpUtil";
import PhoneNumWithValidation from "../../components/PhoneNum/PhoneNumWithValidation";
import UserNameField from "./components/userName/UserNameInputField";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { FaCheckCircle } from "react-icons/fa";
import OtpInput from "../../pages/Authentication/OtpInput";
import { ThreeBounce } from "better-react-spinkit";
import "./PreUboDashboard.scss";

const btnStyle = {
  backgroundColor: '#1678AE',
  height: "40px",
  padding: "0 16px",
  fontSize: "16px",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#FFF"
};

const PreUboDashboard = ({ uboData, onSuccess }) => {
  const { showError, showMessage } = useContext(DialogContext);
  const [phoneData, setPhoneData] = useState({
    countryCode: uboData?.mobileCountryCode || "",
    phone: uboData?.mobilePhone || "",
    phoneVerifiedViaOTP: uboData?.mobilePhoneVerifiedViaOTP || false
  });
  const [username, setUsername] = useState(uboData?.cyclosUserName || "");
  const [isValidUsername, setIsValidUsername] = useState(null);
  const [usernameError, setUsernameError] = useState(null);
  const [loadingUsername, setLoadingUsername] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpId, setOtpId] = useState(null);
  const [otpError, setOtpError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shouldPerformOtpVerification, setShouldPerformOtpVerification] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  const getSysConfigForMobileOtpVerification = async () => {
    setLoading(true)
    try {
      const response = await doGET("/api/sys-config/validateMobileByOTP/detail");
      if (response?.status === 200) {
        setShouldPerformOtpVerification(response.data?.validateMobileByOTP ?? false);
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }


  const handlePhoneChange = (newPhoneData) => {
    setPhoneData({ ...newPhoneData, phoneVerifiedViaOTP: false });
  };

  const handleSendOtp = async () => {
    setLoadingOtp(true);
    try {
      const fullPhoneNumber = `${phoneData.countryCode}${phoneData.phone}`;
      const response = await doGET(`/api/message/send/otp?to=${fullPhoneNumber}&ref_id=${uboData?.application_id}&platform=web`);
      if (response?.data?._id) {
        setOtpId(response.data._id);
        showMessage("OTP sent successfully!");
        setModalOpen(true);
        setOtpError("");
      } else {
        showError("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      showError("Error sending OTP. Please try again.");
    } finally {
      setLoadingOtp(false);
    }
  };

  const handleOtpVerification = async () => {
    if (otp.length !== 4) {
      setOtpError("Please enter a valid 4-digit OTP.");
      return;
    }
    setLoadingOtp(true);
    try {
      const response = await doPUT("/api/business-lite/individual/otp-verification", {
        ubo_id: uboData?._id,
        form_id: uboData?.application_id,
        otpValue: otp,
        otp_id: otpId,
        phone: phoneData.phone,
        countryCode: phoneData.countryCode,
      });
      if (response.status === 200) {
        showMessage("OTP verified successfully!");
        setPhoneData({ ...phoneData, phoneVerifiedViaOTP: true });
        setModalOpen(false);
        setOtpError(null);
      } else {
        setOtpError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      setOtpError(error?.message ?? "Error verifying OTP. Please try again.");
    } finally {
      setLoadingOtp(false);
    }
  };


  const handleNext = async () => {
    if (!isValidUsername) {
      setUsernameError("Please enter a valid, unique username.");
      return;
    }
    if (shouldPerformOtpVerification ? !phoneData.phoneVerifiedViaOTP : false) {
      setOtpError("Please verify your phone with OTP.");
      return;
    }

    setLoading(true)
    try {
      await doPUT("/api/business-lite/set-individual", {
        ubo_id: uboData?._id,
        form_id: uboData?.application_id,
        username: username,
      });
      onSuccess();
    } catch (error) {
      showError("Error updating details. Please try again.");
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    getSysConfigForMobileOtpVerification();
  }, [])

  return (
    <div  className=" d-flex align-items-center justify-content-center">
      <div className="fv_pre_ubo-container mt-3 flex-1">
        <h2 className="fv_pre_ubo-title">Verify Your Details</h2>

        <div className="fv_pre_ubo-input-group">
          <UserNameField
            applicationId={uboData?.application_id}
            individual_id={uboData?._id}
            email={uboData?.email}
            formType="BUSINESS_PLUS"
            label="Username"
            required
            className="fv_pre_ubo-username-field"
            value={username}
            onChange={(newUsername) => { setUsername(newUsername); }}
            loading={loadingUsername}
            isValidUsername={isValidUsername}
            setIsValidUsername={setIsValidUsername}
            err={usernameError}
            handleLoading={setLoadingUsername}
          />
        </div>

        <div className="fv_pre_ubo-input-group-phone">
          <div className="flex-1">

            <PhoneNumWithValidation
              label="Mobile Phone"
              group="Businesses_Lite"
              value={phoneData}
              onChange={handlePhoneChange}
              isDisabled={false}
              notifyParent={(isValid) => setIsValidPhone(isValid)}
              onError={({ errorMsg, hasError }) => setOtpError(hasError ? errorMsg : null)}
              showErr={otpError}
              required
              form_id={uboData?.application_id}
              individual_id={uboData?._id}
              placeholder="Mobile Phone"
            />
          </div>

          {shouldPerformOtpVerification ? <div className="ms-2" >
            {phoneData.phoneVerifiedViaOTP ? (
              <FaCheckCircle style={{ marginTop: "50px" }} color="#28a745" title="OTP Verified" className="otp-verified-icon" />
            ) : (
              <Button

                onClick={handleSendOtp}
                disabled={!phoneData.phone || !phoneData.countryCode || !isValidPhone || loadingOtp}
                style={{ ...btnStyle, marginTop: "40px" }}
              >
                {loadingOtp ? <ThreeBounce size={10} color="#FFFFFF" /> : "Send OTP"}
              </Button>
            )}
          </div> : null}

        </div>


        {shouldPerformOtpVerification ?
          <Modal isOpen={modalOpen} toggle={toggleModal} centered className="fv_modal-content">
            <ModalBody className="fv_modal-body pt-4">
              <p>Enter the OTP sent to {phoneData.countryCode}-{phoneData.phone}</p>
              <div className="fv_otp-input-container">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  style={{
                    borderRadius: "14px",
                    border: "1px solid #C6F3FF",
                    height: "50px",
                    width: "50px",
                    textAlign: "center",
                    fontSize: "18px",
                    margin: "0 5px"
                  }}
                />
              </div>
              {otpError && <div className="fv_error-message">{otpError}</div>}
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={handleOtpVerification}
                disabled={otp.length !== 4 || loadingOtp}
                style={btnStyle}
              >
                {loadingOtp ? <ThreeBounce size={10} color="#FFFFFF" /> : "Verify OTP"}
              </Button>
              <Button
                onClick={toggleModal}
                color="secondary"
                className="fv_btn-secondary"
                style={{
                  backgroundColor: "#f1faff",
                  color: "#007bff",
                  border: "1px solid #c6f3ff",
                  borderRadius: "10px",
                  padding: "8px 16px",
                  fontSize: "16px"
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal> : null}

        <Button style={btnStyle} onClick={handleNext} disabled={!isValidUsername || (shouldPerformOtpVerification ? !phoneData.phoneVerifiedViaOTP : false)} className="fv_pre_ubo-button fv_pre_ubo-next-button">
          {loading ? <ThreeBounce size={10} color="#FFFFFF" /> : "Next"}
        </Button>

      </div>
    </div>

  );
};

export default PreUboDashboard;
