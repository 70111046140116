import React, { useState, useEffect, useRef } from 'react';

const AmountField = ({
  className = '',
  label = 'Amount',
  value: initialValue = '',
  onChange,
  maxValue = 2147483647,
  isDisabled = false,
  required = false,
  error: externalError,
  showErr = false,
  onError = () => {}
}) => {
  const [displayValue, setDisplayValue] = useState(initialValue || '0.00');
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);

  // Sync external value changes
  useEffect(() => {
    setDisplayValue(initialValue ? parseFloat(initialValue).toFixed(2) : '0.00');
  }, [initialValue]);

  // Sync external errors
  useEffect(() => {
    setError(externalError);
  }, [externalError, showErr]);

  const handleFocus = () => {
    setIsFocused(true);
    setTimeout(() => inputRef.current.select(), 0); // Select all text on focus
  };

  const handleBlur = () => {
    setIsFocused(false);
    let formattedValue = parseFloat(displayValue);
    if (isNaN(formattedValue)) {
      formattedValue = 0;
    }
    formattedValue = formattedValue.toFixed(2);
    setDisplayValue(formattedValue);
    onChange(parseFloat(formattedValue)); // Notify parent with the updated value
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Allow only numbers and up to two decimals
    if (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === '') {
      const numericValue = parseFloat(inputValue);
      if (numericValue > maxValue) {
        setError(`Value cannot exceed ${maxValue}`);
        onError(`Value cannot exceed ${maxValue}`)
      } else {
        setDisplayValue(inputValue);
        setError(null);
        onError(null);
      }
    }
  };


  const renderIntegerPart = () => {
    if (!isFocused) {
      // Convert displayValue to a string and handle undefined or null values gracefully
      const integerPart = String(displayValue || "0").split(".")[0];
      return (
        <span style={{ fontSize: '15px', color: 'black' }}>
          {integerPart}.
        </span>
      );
    }
    return null;
  };

  const renderDecimalPart = () => {
    if (!isFocused) {
      // Extract the decimal part and default to '00' if missing
      const decimalPart = String(displayValue || "0").includes('.')
        ? String(displayValue).split('.')[1].padEnd(2, '0')
        : '00';

      return (
        <span style={{ fontSize: '13px', color: '#555' }}>
          {decimalPart}
        </span>
      );
    }
    return null;
  };

  return (
    <div className={`inputfield__container ${className}`}>
      <div style={{ minWidth: '120px', fontSize: 16, margin: '0 0 5px 5px', fontWeight: '500' }}>
        {label} {required && <span style={{ color: 'red' }}>*</span>}
      </div>
      <div className='inputfield__innercontainer'>
        <div className='inputfield ps-2' style={{ position: 'relative' }}>
          <input
            ref={inputRef}
            type='text'
            value={isFocused ? displayValue : ''}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isDisabled}
            placeholder='0.00'
            className={`inputfield__input ${error ? 'input-error' : ''}`}
            style={{ textAlign: 'left', fontSize: isFocused ? '14px' : '0px', paddingRight: '10px' }}
          />
          <div
            className="position-absolute"
            style={{ position: 'absolute', left: '10px', top: '5px' }}
            onClick={() => inputRef.current.focus()}
          >
            {renderIntegerPart()}
            {renderDecimalPart()}
          </div>
        </div>
      </div>
      
      {showErr && error && <div className="inputfield__error">{error}</div>}
    </div>
  );
};

export default AmountField;
