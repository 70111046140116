import React from 'react';
import { Col, Row } from 'reactstrap';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import _ from 'lodash';
import { WrapperField } from '../../../../../components/InputField/WrapperField';



const AboutBusiness = ({ asLabel, editMode, data, handleChange, pageNum, loading, step, decrementStep, handleSubmit, bPrimeJSON, showErr, setErrors }) => {


    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>About your business</h2>

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["primaryBusiness"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, primaryBusiness: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasBusinessPlan"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasBusinessPlan: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["productsOrServices"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, productsOrServices: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["businessPurpose"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, businessPurpose: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["primaryCustomer"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, primaryCustomer: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["revenueRange"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, revenueRange: errorMsg }))}
                />

                <Row>
                    <Col className='col-md-6 col-12'>

                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["hasAuditStatements"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            className="my-3"
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasAuditStatements: errorMsg }))}
                        />

                    </Col>

                    <Col className='col-md-6 col-12'>
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["hasAnnualStatements"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            className="my-3"
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasAnnualStatements: errorMsg }))}
                        />

                    </Col>
                </Row>

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasFinancialForecast"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    className="my-3"
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasFinancialForecast: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["businessInvolvements"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, businessInvolvements: errorMsg }))}
                />


            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel || editMode}
                limit={10}
                onNextClick={() => handleSubmit({ isLastStep: false })}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default AboutBusiness;
