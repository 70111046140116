import React, { useContext, useState } from 'react';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import { DialogContext } from '../../../../../store/context/DialogContext';
import { WrapperField } from '../../../../../components/InputField/WrapperField';



const MoneyServicesBusiness = ({ asLabel, editMode, data, handleChange, pageNum, loading, step, decrementStep, handleSubmit, bPrimeJSON, showErr, setErrors }) => {


    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Money Services Business</h2>

                <p className='pt-2'>
                    For the purposes of this application, FV Bank considers all of the following to be Money Services Businesses, whether a license is required or not in your jurisdictions. (Exchanges, OTC Providers, Liquidity Providers, Wallet Providers, Non-Bank Financial Institutions, VASPS, DLT's EMI's, PSD license holders, Brokers, Remittance Providers, and FX Providers.)
                </p>
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["isMoneyServiceBusiness"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, isMoneyServiceBusiness: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["primaryMsbBusinessModel"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, primaryMsbBusinessModel: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasUsCustomers"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasUsCustomers: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["registeredWithFinCen"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, registeredWithFinCen: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasMsbLicense"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasMsbLicense: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["msbLicenseLocation"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, msbLicenseLocation: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["holdsLicense"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, holdsLicense: errorMsg }))}
                />
                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["licenseDetails"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, licenseDetails: errorMsg }))}
                />

            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel || editMode}
                limit={10}
                onNextClick={() => handleSubmit({ isLastStep: false })}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    );
};

export default MoneyServicesBusiness;
